import { getMonthsDays, getWeekdaysNames } from '../utils/utils'

export const monthsShort = getMonthsDays({ length: 3 })
export const months = getMonthsDays()
export const weekdays = getWeekdaysNames()
export const weekdaysShort = getWeekdaysNames({ length: 2 })
export const weekdaysShortAlt = getWeekdaysNames({ length: 3 })

const en = {
  MONTHS_SHORT: monthsShort,
  MONTHS: months,
  WEEKDAYS: weekdays,
  WEEKDAYS_SHORT: weekdaysShort,
  WEEKDAYS_SHORT_ALT: weekdaysShortAlt,

  APP_TITLE: 'SmartPTR',
  SEARCH_PLACEHOLDER: 'Search',
  BOOKED: 'Booked',
  LEFT: 'Left',
  PLANNED: 'Planned',
  BACK_TO: 'back to',
  USER: 'User',
  COST_CENTER: 'Cost Center',
  EXPORT_OPTIONS: 'Export Options',
  SETTINGS: 'Settings',
  HOURS_PER_DAY: 'Hours per day',
  TIME_TO_BOOK: 'Time to book',
  WORKS_DAY: 'Work days',
  PRINT: 'Print',
  PRINT_EXPORT: 'Print/Export',
  EXPORT_TO_EXCEL: 'Export to Excel',
  SAVE: 'Save',
  COMMENT: 'Comment',
  FILTERS: 'Filters',
  APPLY_FILTERS: 'Apply filters',
  DISABLE_FILTERS: 'Disable filters',
  AUTHENTICATION_INFORMATION: 'Authentication Information',
  LOADING: 'Loading',
  USE_SEARCH_TO_FIND: 'Use search to find element',
  NO_RESULTS_FOUND: 'No results found',
  END_DATE: 'End date',
}

export default en
