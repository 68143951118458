import React from 'react'
import { round } from '../utils/utils'

const HoursTag = ({
  value,
  icon,
  changed = false,
  danger = false,
  success = false,
  muted = false,
}: {
  value: number
  icon: JSX.Element
  changed?: boolean
  danger?: boolean
  success?: boolean
  muted?: boolean
}): JSX.Element => {
  let color = 'blue'
  if (muted) color = 'gray'
  if (success) color = 'green'
  if (changed) color = 'yellow'
  if (danger) color = 'red'

  return (
    <div className={`hours-tag ${color}`}>
      {icon}
      <div>
        {round(value)}
        <small>h</small>
      </div>
    </div>
  )
}

export default HoursTag
