import React from 'react'
import { observer } from 'mobx-react-lite'
import { store } from '../store/Store'

const Highlight = observer(
  ({ children = '', search }: { children: string; search?: string }) => {
    const searchValue = (
      search || store.elements.filters.searchHighlight
    ).trim()

    const str = children.toString()
    const index = str.toLowerCase().indexOf(searchValue.toLowerCase())
    if (!(searchValue && index !== -1)) return <span>{str}</span>

    const array = [
      str.slice(0, index),
      str.slice(index, index + searchValue.length),
      str.slice(index + searchValue.length),
    ]
    return (
      <span>
        <span>{array[0]}</span>
        <span className='highlight' style={{ background: 'yellow' }}>
          {array[1]}
        </span>
        <span>{array[2]}</span>
      </span>
    )
  }
)

export default Highlight
