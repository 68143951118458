import React from 'react'
import { observer } from 'mobx-react-lite'
import { store } from '../store/Store'
import { Flipper, Flipped, spring } from 'react-flip-toolkit'
import Tile from './Tile'
import { i18n } from '../i18n/i18n'
import { InfinityScroll } from './InfinityScroll'

const Tiles = observer(() => {
  const { filtered } = store.elements
  const { query, isSearching } = store.elements.filters

  const onElementAppear = (el: HTMLElement, index: number) =>
    spring({
      onUpdate: val => {
        if (!isNaN(+val)) el.style.opacity = '' + val
      },
      delay: index * 50,
    })

  const onExit = (
    el: HTMLElement,
    index: number,
    removeElement: () => void
  ) => {
    spring({
      config: { overshootClamping: true },
      onUpdate: val => {
        if (!isNaN(+val)) el.style.opacity = '' + (1 - +val)
      },
      onComplete: removeElement,
    })

    return () => {
      el.style.opacity = ''
      removeElement()
    }
  }

  if (isSearching && query)
    return (
      <div>
        <h3 style={{ padding: '20px' }}>{i18n.LOADING}...</h3>
      </div>
    )

  if (!filtered.length && !query)
    return (
      <div>
        <h3 style={{ padding: '20px' }}>{i18n.USE_SEARCH_TO_FIND}</h3>
      </div>
    )

  if (!filtered.length)
    return (
      <div>
        <h3 style={{ padding: '20px' }}>{i18n.NO_RESULTS_FOUND}</h3>
      </div>
    )

  return (
    <InfinityScroll className='content-right' elements={filtered}>
      {elements => (
        <Flipper
          flipKey={elements.map(m => m.id).join('')}
          className='tiles-fliper'
        >
          <div className='tiles' style={{ position: 'relative' }}>
            {elements.map(element => (
              <Flipped
                key={element.id}
                flipId={element.id}
                onAppear={query ? undefined : onElementAppear}
                onExit={query ? undefined : onExit}
              >
                <div>
                  <Tile element={element} />
                </div>
              </Flipped>
            ))}
          </div>
        </Flipper>
      )}
    </InfinityScroll>
  )
})

export default Tiles
