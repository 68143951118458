import { Intent, IToastProps, Position, Toaster } from '@blueprintjs/core'

class ZToaster {
  toaster = Toaster.create({
    className: 'recipe-toaster',
    position: Position.TOP,
  })

  style(intent?: Intent) {
    switch (intent) {
      case Intent.SUCCESS:
        return 'background-color: green; color: white'
      case Intent.DANGER:
        return 'background-color: red; color: white'
      default:
        return 'background-color: blue; color: white'
    }
  }

  show(props: IToastProps, key?: string | undefined): string {
    // eslint-disable-next-line no-console
    console.log(
      `${Date()}\nAlert: %c ${props.message} `,
      this.style(props.intent)
    )
    return this.toaster.show(props, key)
  }
}

export const AppToaster = new ZToaster()
