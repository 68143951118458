import { observer } from 'mobx-react-lite'
import React from 'react'
import { Placement, Tooltip2 } from '@blueprintjs/popover2'

const TOOLTIP_OPEN_DELAY = 300

export const ToolTip = observer(
  ({
    value,
    tooltip,
    placement = 'bottom',
  }: {
    value: React.ReactElement
    tooltip: string
    placement?: Placement
  }) => {
    return (
      <Tooltip2
        content={tooltip}
        placement={placement}
        hoverOpenDelay={TOOLTIP_OPEN_DELAY}
      >
        {value}
      </Tooltip2>
    )
  }
)
