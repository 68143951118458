import { makeAutoObservable, runInAction } from 'mobx'
import { api } from '../api/api'
import { isBetweenDates, isSameDate, sortBy } from '../utils/utils'
import { FiltersStore } from './FiltersStore'
import { Day, PSElement } from './PSElement'
import { store } from './Store'

export class ElementsStore {
  filters = new FiltersStore()

  elements: Record<string, PSElement> = {}

  days: Day[] = []

  isSaving = false
  isStarting = true
  isRefreshing = false

  constructor() {
    makeAutoObservable(this)
    this.init()
  }

  private init = async (): Promise<void> => {
    await this.refreshAllHours()
  }

  get all(): PSElement[] {
    return Object.values(this.elements)
  }

  get usedElements(): string[] {
    return [...new Set(this.days.map(day => day.elementID))]
  }

  get missingElements(): string[] {
    const missingUsed = this.usedElements.filter(
      elementID => !this.elements[elementID]
    )
    const missingPinned = store.user.pinned.filter(
      elementID => !this.elements[elementID]
    )

    return [...new Set([...missingUsed, ...missingPinned])].sort((a, b) =>
      a > b ? 1 : -1
    )
  }

  get inSelectedDay(): PSElement[] {
    const { selectedDay } = store.ui
    return this.all.filter(element =>
      element.days.some(day => selectedDay && isSameDate(day.date, selectedDay))
    )
  }

  get selected(): PSElement | undefined {
    return store.ui.selectedElement
      ? this.elements[store.ui.selectedElement]
      : undefined
  }

  get searchElements(): PSElement[] {
    return this.filters.searchElementIDs.map(
      id => this.elements[id] || new PSElement(id, id, '', '', 'blocked', '')
    )
  }

  get filtered(): PSElement[] {
    if (this.filters.query) {
      return this.searchElements
    }

    return this.visible
  }

  get visible(): PSElement[] {
    return this.all
      .filter(e => e.isVisible)
      .sort((a, b) => sortBy(a, b, ['pinned', 'text']))
  }

  get timeInDay(): number {
    return this.all.reduce((p, c) => p + c.dayHours, 0)
  }

  get editedDays(): Day[] {
    return this.all
      .filter(e => e.days.length > 0)
      .map(e => e.days.filter(d => d.isEdited))
      .reduce((p, c) => {
        p.push(...c)
        return p
      }, [])
  }

  get isSthToSave(): boolean {
    return this.editedDays.length > 0
  }

  get isLoading(): boolean {
    return (
      this.isStarting ||
      this.isSaving ||
      this.isRefreshing ||
      this.filters.isSearching !== 0
    )
  }

  get isEditedDay(): boolean {
    return this.all.some(element => element.isEditedDay)
  }

  setElements = (elements: PSElement[]): void => {
    for (const element of elements) {
      this.elements[element.id] = element
    }
  }

  setIsStarting = (starting: boolean): void => {
    this.isStarting = starting
  }

  addElements = (elements: PSElement[]): void => {
    for (const element of elements) {
      if (!this.elements[element.id]) {
        this.elements[element.id] = element
      }
    }
  }

  update = async (): Promise<void> => {
    await api.updateDays(this.editedDays)
  }

  updateElements = async (): Promise<void> => {
    const data = await api.getElements(this.missingElements)
    this.addElements(data)
  }

  refreshHours = async (): Promise<void> => {
    runInAction(() => (this.isRefreshing = true))
    const from = store.user.openPeriodsStart
    const to = store.user.openPeriodsEnd
    const data = await api.getDays(from, to)
    runInAction(() => {
      const days = data.map(
        day =>
          new Day(
            new Date(day.work_date),
            day.hours,
            day.comment,
            day.wbs_element,
            false
          )
      )

      const daysEdited: Day[] = []
      for (const day of this.days) {
        if (isBetweenDates(from, to, day.date)) {
          const found = days.find(
            d => isSameDate(day.date, d.date) && d.elementID === day.elementID
          )
          if (found) {
            day.refresh(found)
            daysEdited.push(found)
          } else {
            day.reset()
          }
        }
      }

      this.days.push(
        ...days.filter(day => !daysEdited.some(dayEdited => day === dayEdited))
      )
      this.isRefreshing = false
    })
  }

  refreshAllHours = async (): Promise<void> => {
    runInAction(() => (this.isRefreshing = true))
    const data = await api.getAllDays()
    runInAction(() => {
      this.days = data.map(
        day =>
          new Day(
            new Date(day.work_date),
            day.hours,
            day.comment,
            day.wbs_element,
            false
          )
      )
      this.isRefreshing = false
    })
  }

  save = async (): Promise<void> => {
    if (!this.isSthToSave) return

    runInAction(() => (this.isSaving = true))
    await this.update()
    await this.refreshHours()
    runInAction(() => (this.isSaving = false))
  }

  clearInitialDays = (): void => {
    this.days = this.days.filter(day => !day.isInitial)
  }
}
