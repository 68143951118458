import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from '../store/Store'
import cx from 'classnames'
import { Day } from '../store/PSElement'
import { Icons } from './Icons'
import { Popover2 } from '@blueprintjs/popover2'
import { properInput } from '../utils/utils'
import { i18n } from '../i18n/i18n'

const ElementPopover = observer(
  ({ date, day }: { date: Date; day: Day | undefined }): JSX.Element | null => {
    const ref = useRef<HTMLTextAreaElement>(null)
    const { selected } = store.elements
    const { canEditInDate } = store.user

    useEffect(() => {
      const element = ref.current
      if (element) {
        element.focus()
        element.setSelectionRange(element.value.length, element.value.length)
      }
    })

    if (!selected) return null

    const update = (day: Day, value: string): void => {
      if (value || day.hours) day.comment = value
      else selected.deleteDay(day)
    }

    return (
      <div
        className='element-popover'
        onClick={() => {
          ref.current?.focus()
        }}
      >
        <div className='element-line'>
          <h5>{i18n.COMMENT}</h5>
          {day ? (
            <>
              <textarea
                ref={ref}
                autoFocus
                className='form-control'
                value={day.comment}
                disabled={!canEditInDate(date) || !selected.canEdit}
                onChange={e => update(day, e.target.value)}
              />
            </>
          ) : (
            <>
              <textarea
                ref={ref}
                autoFocus
                className='form-control'
                value={''}
                disabled={!canEditInDate(date) || !selected.canEdit}
                onChange={e =>
                  e.target.value &&
                  selected.addDay(new Day(date, 0, e.target.value, selected.id))
                }
              />
            </>
          )}
        </div>
      </div>
    )
  }
)

const ElementDay = observer(
  ({ date, day }: { date: Date; day: Day | undefined }): JSX.Element | null => {
    const { canEditInDate } = store.user
    const ref = useRef<HTMLInputElement>(null)
    const [isOpen, setOpen] = useState(false)
    const { selected } = store.elements
    const noZero = (value: string | number): string => (value ? `${value}` : '')
    const [value, setValue] = useState(`${noZero(day?.hours || 0)}`)

    useEffect(() => {
      if (day) {
        setValue(`${day.hours}`)
      }
    }, [day])

    if (!selected) return null

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newValue = properInput(e.target.value)
      if (!isNaN(+newValue)) {
        if (day) day.hours = +newValue
        setValue(newValue)
      }
    }

    return (
      <>
        <div
          className='box'
          title={day?.comment}
          onClick={() => {
            if (isOpen) return
            ref.current?.focus()
            ref.current?.select()
          }}
        >
          <div className='date'>{date.getUTCDate()}</div>
          <div className='box-comment'>
            <Popover2
              content={<ElementPopover date={date} day={day} />}
              isOpen={isOpen}
              onClose={() => setOpen(false)}
              placement='left'
            >
              <div
                className={cx('comment-icon', {
                  open: isOpen,
                  active: day?.comment,
                  changed: day?.isCommentEdited,
                  deleted: day?.isCommentDeleted,
                })}
                onClick={() => setOpen(true)}
              >
                <Icons.CommentTextOutline />
              </div>
            </Popover2>
          </div>
          {day ? (
            <input
              ref={ref}
              className={cx('hours', { edited: day.isHoursEdited })}
              value={value}
              disabled={!canEditInDate(date) || !selected.canEdit}
              onChange={onChange}
            />
          ) : (
            <input
              ref={ref}
              className='hours'
              value={noZero(0)}
              disabled={!canEditInDate(date) || !selected.canEdit}
              onChange={e =>
                !isNaN(+e.target.value) &&
                selected.addDay(new Day(date, +e.target.value, '', selected.id))
              }
            />
          )}
        </div>
      </>
    )
  }
)

const ElementCalendar = observer(() => {
  const { selected } = store.elements
  const { month } = store.calendar
  const { isSameMonth } = store.ui

  const getUTCMonth = (): (Day | undefined)[] => {
    return month.map((day, i) => {
      return selected
        ? selected.days
            .filter(day => isSameMonth(day.date))
            .find(day => day.date.getUTCDate() === i + 1)
        : undefined
    })
  }

  const monthDays = getUTCMonth()

  return (
    <div className='calendar'>
      {month.map((day, i) => (
        <div
          key={i}
          className={cx('day', { off: day.off })}
          style={i === 0 ? { gridColumnStart: day.date.getUTCDay() || 7 } : {}}
        >
          <ElementDay date={day.date} day={monthDays[i]} />
        </div>
      ))}
    </div>
  )
})

export default ElementCalendar
