import React from 'react'

const DateTag = ({
  value,
  icon,
  changed = false,
  danger = false,
  success = false,
  muted = false,
  white = false,
  title = '',
}: {
  value: string
  icon: JSX.Element
  changed?: boolean
  danger?: boolean
  success?: boolean
  muted?: boolean
  white?: boolean
  title?: string
}): JSX.Element => {
  let color = 'blue'
  if (muted) color = 'gray'
  if (success) color = 'green'
  if (changed) color = 'yellow'
  if (danger) color = 'red'
  if (white) color = 'white'

  const [year, month, day] = value.split('-')

  return (
    <div className={`date-tag ${color}`} title={title}>
      {icon}
      <div className='date'>
        <div>
          <span>{day}</span>
          <span>-</span>
          <span>{month}</span>
        </div>
        <div className='year'>{year}</div>
      </div>
    </div>
  )
}

export default DateTag
