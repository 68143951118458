import en from './en'
// import pl from './pl' // 1 of 2 - import language file

export type I18n = typeof en

const langs = {
  en,
  // pl, // 2 of 2 - add new language
}

type Lang = keyof typeof langs

const availableLanguages = Object.keys(langs)

export function createI18N(lang: Lang): I18n {
  return Object.assign({}, langs['en'], langs[lang])
}

export function getLang(): Lang {
  const params = new URLSearchParams(window.location.search)

  let lang
  for (const pair of params.entries()) {
    const [key, value] = pair
    if (
      ['lang', 'sap-language', 'sap-ui-language'].includes(key.toLowerCase())
    ) {
      lang = value.toLowerCase()
      break
    }
  }
  if (!lang) {
    lang = window.navigator.language
  }
  if (!availableLanguages.includes(lang)) {
    lang = availableLanguages[0]
  }
  return lang as Lang
}

export const i18n = createI18N(getLang())
declare global {
  interface Window {
    i18n: I18n
  }
}
window.i18n = i18n
