import { makeAutoObservable } from 'mobx'
import { round } from '../utils/utils'
import { store } from './Store'

export class MonthStore {
  constructor() {
    makeAutoObservable(this)
  }

  get allTime(): number {
    const { hoursPerDay, percentTimeToBook } = store.user
    const { month } = store.calendar
    return (
      (month.reduce((p, c) => (!c.off ? p + hoursPerDay : p), 0) *
        percentTimeToBook) /
      100
    )
  }

  get allBookedTime(): number {
    const { all } = store.elements
    return all.reduce((p, c) => p + c.hours, 0)
  }

  get allDifferentTime(): number {
    const { all } = store.elements
    return all.reduce((p, c) => p + c.different, 0)
  }

  get allLeftTime(): number {
    return this.allTime - this.allBookedTime
  }

  get percentComplete(): number {
    return this.allTime > 0 ? (this.allBookedTime / this.allTime) * 100 : 0
  }

  get percentSaved(): number {
    return this.allTime > 0
      ? ((this.allBookedTime - this.allDifferentTime) / this.allTime) * 100
      : 0
  }

  get percentDifferent(): number {
    return this.allTime > 0 ? (this.allDifferentTime / this.allTime) * 100 : 0
  }

  get bookedDays(): number[] {
    const { all } = store.elements
    const { month } = store.calendar
    const { isSameMonth } = store.ui

    const days = all
      .map(m => [...m.days])
      .flat()
      .filter(day => isSameMonth(day.date))
    return month.map((day, i) => {
      return round(
        days
          .filter(day => day.date.getUTCDate() === i + 1)
          .reduce((p, c) => p + c.hours, 0)
      )
    })
  }

  get editedDays(): boolean[] {
    const { all } = store.elements
    const { month } = store.calendar
    const { isSameMonth } = store.ui

    const days = all
      .map(m => [...m.days])
      .flat()
      .filter(day => isSameMonth(day.date))
    return month.map((day, i) => {
      return days
        .filter(day => day.date.getUTCDate() === i + 1)
        .some(day => day.isEdited)
    })
  }
}
