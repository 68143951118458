import React, { useState } from 'react'

export function InfinityScroll<T>({
  children,
  elements,
  className,
  startLimit = 100,
}: {
  children: (elements: T[]) => React.ReactNode
  elements: T[]
  className?: string
  startLimit?: number
}): JSX.Element {
  const [limit, setLimit] = useState(startLimit)

  return (
    <div
      className={className}
      onScroll={event => {
        const target = event.currentTarget
        const scrollPosition = target.scrollTop + target.offsetHeight
        const scrollLoadLevel = event.currentTarget.scrollHeight * 0.95
        if (scrollPosition > scrollLoadLevel && limit < elements.length) {
          setLimit(limit + 20)
        }
      }}
    >
      {children(elements.slice(0, limit))}
    </div>
  )
}
