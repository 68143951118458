import { CalendarStore } from './CalendarStore'
import { UserStore } from './UserStore'
import { MonthStore } from './MonthStore'
import { ElementsStore } from './ElementsStore'
import { UI } from './UI'
import { reaction } from 'mobx'
import { UrlParams } from './UrlParams'
import { LoginStore } from './LoginStore'
import { ErrorStore } from './ErrorStore'

export class Store {
  ui: UI
  calendar = new CalendarStore()
  user = new UserStore()
  month = new MonthStore()
  elements = new ElementsStore()
  login = new LoginStore()
  error = new ErrorStore()

  constructor(date = new Date(), url = new UrlParams()) {
    this.ui = new UI(date, url)
  }

  get isSthToSave(): boolean {
    return (
      this.elements.isSthToSave ||
      this.user.isSthToSave ||
      this.user.period.isSthToSave
    )
  }

  get isLoading(): boolean {
    return this.elements.isLoading || this.user.isLoading
  }

  save = (): void => {
    this.elements.save()
    this.user.savePreferences()
  }
}

export let store = new Store()

reaction(
  () => store.ui.params,
  params => window.history.replaceState(null, 'Koty', params)
)
reaction(
  () => store.elements.filters.query,
  query => store.elements.filters.search(query)
)
reaction(
  () => store.elements.missingElements,
  async () => {
    await store.elements.updateElements()
    setTimeout(() => store.elements.setIsStarting(false), 2000)
  }
)

const beforeUnloadListener = (event: BeforeUnloadEvent) => {
  event.preventDefault()
  event.returnValue = ''
}

reaction(
  () => store.isSthToSave,
  isSthToSave => {
    if (isSthToSave) addEventListener('beforeunload', beforeUnloadListener)
    else removeEventListener('beforeunload', beforeUnloadListener)
  }
)

export function overwriteStore(newStore: Store): void {
  store = newStore
}

declare global {
  interface Window {
    store: Store
  }
}
window.store = store
