import { makeAutoObservable } from 'mobx'

export class ErrorStore {
  private applicationServerErrorHTML?: string

  constructor() {
    makeAutoObservable(this)
  }

  get applicationServerErrorURI(): string | undefined {
    if (this.applicationServerErrorHTML)
      return `data:text/html;charset=utf-8,${encodeURIComponent(
        this.applicationServerErrorHTML
      )}`

    return undefined
  }

  setApplicationServerError = (error: string) => {
    this.applicationServerErrorHTML = error
  }

  clearApplicationServerError = () => {
    this.applicationServerErrorHTML = undefined
  }
}
