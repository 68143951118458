import { i18n } from '../i18n/i18n'
import { store } from '../store/Store'

const printHTML = (html: string): void => {
  const iframe = document.createElement('iframe')
  iframe.setAttribute('hidden', 'true')
  iframe.onload = () => {
    if (iframe.contentDocument) {
      iframe.contentDocument.body.innerHTML = html
      iframe.focus()
      iframe.contentWindow?.print()
      setTimeout(() => document.body.removeChild(iframe))
    }
  }
  document.body.append(iframe)
}

const exportToPrinter = (): void => {
  const { year, month } = store.ui
  const { filtered } = store.elements
  const { username } = store.user

  printHTML(`
    <html>
    <head>
      <title>${i18n.MONTHS[month]} ${year} - ${username} - CATS</title>
      <style>
      @page {
        size: A4 portrait;
        margin: 1cm;
      }

      body {
        font-family: Verdana;
      }

      caption {
        font-size: 6mm;
        text-align: center;
        border: 1px solid black;
        border-bottom: 0;
      }

      h3 {
        font-size: 4.233333333395mm;
        line-height: .3;
      }

      .secondTable span {
        line-height: .4;
      }

      table {
        width: 100%;
        margin: 6px 0 0;
        border-collapse: collapse;
      }

      table, th, td {
        border: 1px solid black;
        padding: 1mm;
      }

      th {
        font-size: 2.6mm;
      }

      td {
        font-size: 2.1mm;
      }

      thead { display: table-header-group; }
      </style>
    </head>
    <body>
      <table>
        <caption><b>${i18n.MONTHS[month]} ${year} - ${username}</b></caption>
        <thead>
          <tr>
            <th>WBS ID</th>
            <th>WBS Description</th>
            <th>Project Description</th>
            <th>All hours</th>
            <th>Booked Hours</th>
          </tr>
        </thead>
        ${filtered
          .map(
            element => `
        <tr>
          <td>${element.id}</td>
          <td>${element.description}</td>
          <td>${element.title}</td>
          <td>${element.allHours}</td>
          <td>${element.hours}</td>
        </tr>
        `
          )
          .join('')}
      </table>
    </body>
    </html>
  `)
}

export default exportToPrinter
