import { makeAutoObservable, runInAction } from 'mobx'
import { api } from '../api/api'
import { store } from './Store'
import { sleep } from '../utils/utils'

export class FiltersStore {
  searchValue = ''
  searchElementIDs: string[] = []
  isSearching = 0
  isFilterActive = false
  isAsideOpen = false

  onlyOpen = true
  wbsElement = ''
  description = ''
  projectDescription = ''
  projectNumber = ''
  leadingCompany = ''
  characteristic1 = ''
  characteristic2 = ''
  characteristic3 = ''
  gdpepPhase = ''
  costCenter = ''
  profitCenter = ''
  reqCostCenter = ''
  manager = ''
  reqEmployees = ''
  ppm = ''
  prodLvl = ''
  name1 = ''

  constructor() {
    makeAutoObservable(this)
  }

  get searchHighlight(): string {
    return this.isFilterActive ? '' : this.searchValue
  }

  get query(): string {
    const params = new URLSearchParams()

    if (!this.isAsideOpen) {
      if (this.searchValue) {
        params.set('query', this.searchValue)
        params.set('status', 'open')
      }
      return params.toString()
    }

    if (!this.isFilterActive) {
      return params.toString()
    }

    if (this.wbsElement) params.set('wbs_element', this.wbsElement)
    if (this.description) params.set('description', this.description)
    if (this.projectNumber) params.set('project_nr', this.projectNumber)
    if (this.leadingCompany) params.set('leading_company', this.leadingCompany)
    if (this.characteristic1)
      params.set('characteristic1', this.characteristic1)
    if (this.characteristic2)
      params.set('characteristic2', this.characteristic2)
    if (this.characteristic3)
      params.set('characteristic3', this.characteristic3)
    if (this.gdpepPhase) params.set('gdpep_phase', this.gdpepPhase)
    if (this.projectDescription)
      params.set('project_description', this.projectDescription)
    if (this.profitCenter) params.set('profit_center', this.profitCenter)
    if (this.costCenter) params.set('cost_center', this.costCenter)
    if (this.reqCostCenter) params.set('req_cost_center', this.reqCostCenter)
    if (this.manager) params.set('manager', this.manager)
    if (this.reqEmployees) params.set('req_employees', this.reqEmployees)
    if (this.ppm) params.set('ppm', this.ppm)
    if (this.prodLvl) params.set('prod_lvl', this.prodLvl)
    if (this.name1) params.set('name1', this.name1)
    if (this.onlyOpen) params.set('status', 'open')
    else params.set('status', 'all')

    return params.toString()
  }

  setSearch = (value: string): void => {
    this.searchValue = value
  }
  setWbsElement = (value: string): void => {
    this.wbsElement = value
  }
  setDescription = (value: string): void => {
    this.description = value
  }
  setProjectDescription = (value: string): void => {
    this.projectDescription = value
  }
  setProjectNumber = (value: string): void => {
    this.projectNumber = value
  }
  setLeadingCompany = (value: string): void => {
    this.leadingCompany = value
  }
  setCharacteristic1 = (value: string): void => {
    this.characteristic1 = value
  }
  setCharacteristic2 = (value: string): void => {
    this.characteristic2 = value
  }
  setCharacteristic3 = (value: string): void => {
    this.characteristic3 = value
  }

  setGdpepPhase = (value: string): void => {
    this.gdpepPhase = value
  }
  setCostCenter = (value: string): void => {
    this.costCenter = value
  }
  setProfitCenter = (value: string): void => {
    this.profitCenter = value
  }
  setReqCostCenter = (value: string): void => {
    this.reqCostCenter = value
  }
  setManager = (value: string): void => {
    this.manager = value
  }
  setReqEmployees = (value: string): void => {
    this.reqEmployees = value
  }
  setPpm = (value: string): void => {
    this.ppm = value
  }
  setProdLvl = (value: string): void => {
    this.prodLvl = value
  }
  setName1 = (value: string): void => {
    this.name1 = value
  }
  toggleOnlyOpen = (): void => {
    this.onlyOpen = !this.onlyOpen
  }
  toggleFilters = (): void => {
    this.isFilterActive = !this.isFilterActive
  }
  toggleAsideOpen = (): void => {
    this.isAsideOpen = !this.isAsideOpen
    this.searchValue = ''
  }

  search = async (query: string): Promise<void> => {
    runInAction(() => this.isSearching++)
    this.searchElementIDs = []
    await sleep(1000)
    if (query === this.query) {
      const data = await api.searchElements(this.query)
      runInAction(() => {
        store.elements.addElements(data)
        this.searchElementIDs = data.map(element => element.id)
      })
    }

    runInAction(() => this.isSearching--)
  }
}
