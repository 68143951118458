import React from 'react'

const TextTag = ({
  value,
  icon,
}: {
  value: string
  icon: JSX.Element
}): JSX.Element => {
  return (
    <div className={`hours-tag text`}>
      {icon}
      <div>{value}</div>
    </div>
  )
}

export default TextTag
