import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'classnames'
import { store } from '../store/Store'
import { Classes, Dialog } from '@blueprintjs/core'
import { Icons } from './Icons'
import { PSElement } from '../store/PSElement'
import HoursTag from './HoursTag'
import HoursTagInput from './HoursTagInput'
import { properInput } from '../utils/utils'
import { Search } from './TopBar'
import DateTag from './DateTag'
import { i18n } from '../i18n/i18n'
import { InfinityScroll } from './InfinityScroll'

const DayDialogTitle = observer(({ animate }: { animate: () => void }) => {
  const {
    nextDay,
    previousDay,
    selectedDayText,
    isStartOfMonth,
    isEndOfMonth,
  } = store.ui

  return (
    <div className='day-dialog-title'>
      <button
        className={cx('bp3-button bp3-minimal', { hidden: isStartOfMonth })}
        onClick={() => {
          previousDay()
          animate()
        }}
        tabIndex={-1}
      >
        <Icons.ChevronLeft />
      </button>
      {selectedDayText}
      <button
        className={cx('bp3-button bp3-minimal', { hidden: isEndOfMonth })}
        onClick={() => {
          nextDay()
          animate()
        }}
        tabIndex={-1}
      >
        <Icons.ChevronRight />
      </button>
    </div>
  )
})

const DayInput = observer(({ element }: { element: PSElement }) => {
  const [value, setValue] = useState(`${element.dayHours}`)

  useEffect(() => {
    if (+value !== element.dayHours) {
      setValue(`${element.dayHours}`)
    }
  }, [value, element.dayHours])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = properInput(e.target.value)
    if (!isNaN(+newValue)) {
      element.setHours(+newValue)
      setValue(newValue)
    }
  }

  return (
    <HoursTagInput
      value={value}
      icon={<Icons.CalendarToday />}
      onChange={onChange}
      changed={element.day?.isHoursEdited || false}
    />
  )
})

const DayComment = observer(({ element }: { element: PSElement }) => {
  const { canEditInDate } = store.user
  const ref = useRef<HTMLTextAreaElement>(null)

  return (
    <div
      onClick={() => ref.current?.focus()}
      className={cx('comment', {
        active: element.comment,
        changed: element.day?.isCommentEdited || false,
        deleted: element.day?.isCommentDeleted || false,
      })}
    >
      <Icons.CommentTextOutline />
      <textarea
        ref={ref}
        className='form-control'
        placeholder='no comment'
        disabled={
          (element.day && !canEditInDate(element.day.date)) || !element.canEdit
        }
        onChange={e => element.addComment(e.target.value)}
        value={element.comment}
      />
    </div>
  )
})

const DayElement = observer(({ element }: { element: PSElement }) => {
  const { selectedDay } = store.ui
  const { canEditInDate } = store.user

  return (
    <div className='day-element-line'>
      <div>
        <h5>{element.code}</h5> <h6>{element.title}</h6>
      </div>
      <small>{element.description}</small>
      <div className='hours'>
        {element.endDate && (
          <DateTag
            value={element.endDate}
            icon={<Icons.CalendarEnd />}
            white
            title={i18n.END_DATE}
          />
        )}
        <HoursTag
          value={element.allHours}
          icon={<Icons.Sigma />}
          changed={element.isEdited}
        />
        <HoursTag
          value={element.hours}
          icon={<Icons.CalendarMonth />}
          changed={element.isEditedMonth}
        />
        {selectedDay && canEditInDate(selectedDay) && element.canEdit ? (
          <DayInput element={element} />
        ) : (
          <HoursTag value={element.dayHours} icon={<Icons.CalendarToday />} />
        )}
      </div>
      <DayComment element={element} />
    </div>
  )
})

const DayDialogBody = observer(({ elements }: { elements: PSElement[] }) => {
  return (
    <>
      {elements.map(element => (
        <DayElement key={element.id} element={element} />
      ))}
    </>
  )
})

const DayDialog = observer(() => {
  const { closeDayView, isOpenDayView } = store.ui
  const { timeInDay, isEditedDay, filtered } = store.elements
  const { hoursPerDay } = store.user

  const [animate, setAnimate] = useState(false)

  const [isOpen, setOpen] = useState(isOpenDayView)
  useEffect(() => {
    setOpen(isOpenDayView)
  }, [isOpenDayView])
  const onClose = () => {
    setOpen(false)
  }
  const onClosed = () => {
    closeDayView()
  }

  return (
    <Dialog
      title={<DayDialogTitle animate={() => setAnimate(!animate)} />}
      isOpen={isOpen}
      onClose={onClose}
      onClosed={onClosed}
    >
      <InfinityScroll
        className={cx('day-dialog', Classes.DIALOG_BODY, {
          'animate-day-dialog': animate,
          'animate-day-dialog2': !animate,
        })}
        elements={filtered}
        startLimit={20}
      >
        {elements => (
          <>
            <header>
              <Search />
              <div className='hours'>
                <HoursTag
                  value={hoursPerDay}
                  icon={<Icons.CalendarClock />}
                  muted
                />
                <HoursTag
                  value={timeInDay}
                  icon={<Icons.CalendarToday />}
                  changed={isEditedDay}
                />
              </div>
            </header>
            <DayDialogBody elements={elements} />
          </>
        )}
      </InfinityScroll>
    </Dialog>
  )
})

export default DayDialog
