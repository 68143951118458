import React from 'react'
import { observer } from 'mobx-react-lite'
import { store } from '../store/Store'
import cx from 'classnames'
import Rive from '@rive-app/react-canvas'

import RiveAni from './clock.riv'
const SplashScreen = observer(() => {
  const { isStarting } = store.elements

  return (
    <div className={cx('splash-screen', { done: !isStarting })}>
      {/* <div className='spinner'> */}
      <div style={{ height: '400px', width: '400px' }}>
        <Rive src={RiveAni} />
      </div>
      {/* </div> */}
    </div>
  )
})

export default SplashScreen
