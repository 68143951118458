import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'classnames'
import { store } from '../store/Store'
import { Classes, Dialog } from '@blueprintjs/core'
import ElementCalendar from './ElementCalendar'
import { Icons } from './Icons'
import HoursTag from './HoursTag'
import DateTag from './DateTag'
import { i18n } from '../i18n/i18n'

const ElementDialogTitle = observer(({ animate }: { animate: () => void }) => {
  const { selected } = store.elements
  const { nextElement, previousElement } = store.ui

  return (
    <div className='element-dialog-title'>
      <button
        className='bp3-button bp3-minimal'
        onClick={() => {
          previousElement()
          animate()
        }}
        tabIndex={-1}
      >
        <Icons.ChevronLeft />
      </button>
      {selected?.code} - {selected?.description}
      <button
        className='bp3-button bp3-minimal'
        onClick={() => {
          nextElement()
          animate()
        }}
        tabIndex={-1}
      >
        <Icons.ChevronRight />
      </button>
    </div>
  )
})

const ElementDialog = observer(() => {
  const { closeElementView, isOpenElementView, isAfterMonth } = store.ui
  const { selected } = store.elements

  const [animate, setAnimate] = useState(false)

  const [isOpen, setOpen] = useState(isOpenElementView)
  useEffect(() => {
    setOpen(isOpenElementView)
  }, [isOpenElementView])
  const onClose = () => {
    setOpen(false)
  }
  const onClosed = () => {
    closeElementView()
  }

  return (
    <Dialog
      title={<ElementDialogTitle animate={() => setAnimate(!animate)} />}
      isOpen={isOpen}
      onClose={onClose}
      onClosed={onClosed}
    >
      <div
        className={cx('element-dialog', Classes.DIALOG_BODY, {
          animate: animate,
          animate2: !animate,
        })}
      >
        <header>
          <div className='header-title'>
            <div>
              <h5>{selected?.code}</h5> <h6>{selected?.title}</h6>
            </div>
            <small>{selected?.description}</small>
          </div>
          <div className='hours'>
            {selected?.endDate && (
              <DateTag
                value={selected.endDate}
                icon={<Icons.CalendarEnd />}
                white
                title={i18n.END_DATE}
              />
            )}
            <HoursTag
              value={selected?.allHours || 0}
              icon={<Icons.Sigma />}
              changed={selected?.isEdited}
            />
            {!isAfterMonth && (
              <HoursTag
                value={selected?.hours || 0}
                icon={<Icons.CalendarMonth />}
                changed={selected?.isEditedMonth}
              />
            )}
          </div>
        </header>
        <div className='day-detail element-detail'>
          <ElementCalendar />
        </div>
      </div>
    </Dialog>
  )
})

export default ElementDialog
