import { makeAutoObservable } from 'mobx'
import { i18n } from '../i18n/i18n'
import {
  compareDate,
  isSameMonth,
  endOfMonth,
  startOfMonth,
  isStartOfMonth,
  isEndOfMonth,
  addZero,
} from '../utils/utils'
import { store } from './Store'
import { UrlParams } from './UrlParams'

export class UI {
  private today: Date
  private vacation = false
  selectedDay?: Date
  selectedElement?: string

  constructor(private date: Date = new Date(), private url = new UrlParams()) {
    makeAutoObservable(this)
    this.today = new Date(date)

    if (this.url.month) this.date = new Date(this.url.month)
    if (this.url.date) this.selectedDay = new Date(this.url.date)
    if (this.url.element) this.selectedElement = this.url.element
  }

  get params(): string {
    this.url.date = this.selectedDay || null
    this.url.month = this.monthForParam
    this.url.element = this.selectedElement || null
    return this.url.params
  }

  get monthForParam(): Date | null {
    return !this.isTodayMonth ? this.date : null
  }

  get monthYear(): string {
    return `${this.date.getUTCFullYear()}${addZero(
      this.date.getUTCMonth() + 1
    )}`
  }

  get month(): number {
    return this.date.getUTCMonth()
  }

  get year(): number {
    return this.date.getUTCFullYear()
  }

  get endOfMonth(): Date {
    return endOfMonth(this.date)
  }

  get startOfMonth(): Date {
    return startOfMonth(this.date)
  }

  get todayMonth(): number {
    return this.today.getUTCMonth()
  }

  get selectedDayText(): string {
    return this.selectedDay
      ? this.selectedDay.getUTCDate() +
          ' ' +
          i18n.MONTHS[this.selectedDay.getUTCMonth()] +
          ' ' +
          this.selectedDay.getUTCFullYear()
      : ''
  }

  get dateOneMonthBefore(): Date {
    const date = new Date(this.date)
    date.setUTCMonth(this.month - 1, 15)
    return date
  }

  get dateOneMonthAfter(): Date {
    const date = new Date(this.date)
    date.setUTCMonth(this.month + 1, 15)
    return date
  }

  get isTodayMonth(): boolean {
    return isSameMonth(this.date, this.today)
  }

  get isBeforeMonth(): boolean {
    return (
      !isSameMonth(this.date, this.today) &&
      compareDate(this.date, this.today) === -1
    )
  }

  get isAfterMonth(): boolean {
    return (
      !isSameMonth(this.date, this.today) &&
      compareDate(this.date, this.today) === 1
    )
  }

  get isVacationActive(): boolean {
    return this.vacation
  }

  get isOpenDayView(): boolean {
    return this.selectedDay !== undefined
  }

  get isOpenElementView(): boolean {
    return this.selectedElement !== undefined
  }

  get canEditDay(): boolean {
    return this.selectedDay
      ? isSameMonth(this.today, this.selectedDay)
      : this.isTodayMonth
  }

  get canEditMonth(): boolean {
    return store.user.canEditInDate(this.endOfMonth)
  }

  get isStartOfMonth(): boolean {
    return !!this.selectedDay && isStartOfMonth(this.selectedDay)
  }

  get isEndOfMonth(): boolean {
    return !!this.selectedDay && isEndOfMonth(this.selectedDay)
  }

  isSameMonth = (date: Date): boolean => {
    return isSameMonth(date, this.date)
  }

  compareDate = (date: Date): number => {
    return compareDate(date, this.date)
  }

  backToNow = (): void => {
    this.date = new Date(this.today)
  }

  nextMonth = (): void => {
    this.date.setUTCMonth(this.date.getUTCMonth() + 1, 15)
    this.updateDate()
  }

  previousMonth = (): void => {
    this.date.setUTCMonth(this.date.getUTCMonth() - 1, 15)
    this.updateDate()
  }

  updateDate = (): void => {
    this.date = new Date(this.date)
  }

  openDayView = (date: Date): void => {
    this.selectedDay = new Date(date)
  }

  openElementView = (elementID: string): void => {
    this.selectedElement = elementID
  }

  closeDayView = (): void => {
    this.selectedDay = undefined
  }

  closeElementView = (): void => {
    store.elements.clearInitialDays()
    this.selectedElement = undefined
  }

  toggleVacation = (): void => {
    this.vacation = !this.vacation
  }

  nextElement = (): void => {
    const elements = store.elements.filtered

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].id === this.selectedElement) {
        this.selectedElement =
          i === elements.length - 1 ? elements[0].id : elements[i + 1].id
        return
      }
    }
  }

  previousElement = (): void => {
    const elements = store.elements.filtered

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].id === this.selectedElement) {
        this.selectedElement =
          i === 0 ? elements[elements.length - 1].id : elements[i - 1].id
        return
      }
    }
  }

  nextDay = (): void => {
    if (this.selectedDay) {
      const date = new Date(this.selectedDay)
      date.setUTCDate(date.getUTCDate() + 1)
      if (this.isSameMonth(date)) this.selectedDay = date
    }
  }

  previousDay = (): void => {
    if (this.selectedDay) {
      const date = new Date(this.selectedDay)
      date.setUTCDate(date.getUTCDate() - 1)
      if (this.isSameMonth(date)) this.selectedDay = date
    }
  }
}
