import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { PSElement } from '../store/PSElement'
import { store } from '../store/Store'
import { Icons } from './Icons'
import cx from 'classnames'
import Highlight from './Highlight'
import HoursTag from './HoursTag'
import HoursTagInput from './HoursTagInput'
import { properInput } from '../utils/utils'

const Input = observer(({ element }: { element: PSElement }) => {
  const { addHours } = element
  const [value, setValue] = useState('')

  const parseNumber = (value: string) => {
    const number = parseFloat(value.replace(',', '.'))
    return isNaN(number) ? 0 : number
  }

  const updateValue = () => {
    addHours(parseNumber(value))
    setValue('')
  }

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') updateValue()
  }

  return (
    <HoursTagInput
      value={value}
      icon={<Icons.PlusMinusVariant />}
      onChange={e => setValue(properInput(e.target.value))}
      onKeyDown={onKeyDownHandler}
      onBlur={updateValue}
    />
  )
})

const Tile = observer(({ element }: { element: PSElement }) => {
  const {
    openElementView,
    closeElementView,
    selectedElement,
    canEditMonth,
    isAfterMonth,
  } = store.ui
  const { filters } = store.elements

  const onClickHandler = () =>
    element.id === selectedElement
      ? closeElementView()
      : openElementView(element.id)

  return (
    <div className={cx('tile', { active: element.id === selectedElement })}>
      <header>
        <div onClick={onClickHandler} className='title'>
          <Highlight search={filters.wbsElement}>{element.code}</Highlight>
        </div>
        <div onClick={onClickHandler}></div>
        <div className='buttons'>
          {element.isHideIconShow && (
            <button
              className={cx({ pinned: element.hidden })}
              onClick={() => element.toggleHidden()}
              tabIndex={-1}
            >
              {element.hidden ? <Icons.EyeOff /> : <Icons.EyeOffOutline />}
            </button>
          )}
          <button
            className={cx({ pinned: element.pinned })}
            onClick={() => element.togglePinned()}
            tabIndex={-1}
          >
            {element.pinned ? <Icons.Pin /> : <Icons.PinOutline />}
          </button>
        </div>
      </header>
      <div className='tile-details'>
        <div className='tile-title' onClick={onClickHandler}>
          <Highlight search={filters.projectDescription}>
            {element.title}
          </Highlight>
        </div>
        <div className='tile-description' onClick={onClickHandler}>
          <Highlight search={filters.description}>
            {element.description}
          </Highlight>
        </div>
      </div>
      <hr />
      <div className='tile-hours'>
        <HoursTag
          value={element.allHours}
          icon={<Icons.Sigma />}
          changed={element.isEdited}
        />
        {!isAfterMonth && (
          <HoursTag
            value={element.hours}
            icon={<Icons.CalendarMonth />}
            changed={element.isEditedMonth}
          />
        )}
        {canEditMonth && element.canEdit && <Input element={element} />}
      </div>
    </div>
  )
})

export default Tile
