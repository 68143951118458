export const HOURS_INPUT_MAX = 999
export const HOURS_INPUT_MIN = -999

export function sleep(ms: number): Promise<void> {
  return new Promise<void>(resolve => setTimeout(resolve, ms))
}

export function addZero(value: number): string {
  return (value >= 10 ? '' : '0') + value
}

export function isSameYear(dateOne: Date, dateTwo: Date): boolean {
  return dateOne.getUTCFullYear() === dateTwo.getUTCFullYear()
}

export function isSameMonth(dateOne: Date, dateTwo: Date): boolean {
  return (
    isSameYear(dateOne, dateTwo) &&
    dateOne.getUTCMonth() === dateTwo.getUTCMonth()
  )
}

export function isSameDate(dateOne: Date, dateTwo: Date): boolean {
  return (
    isSameMonth(dateOne, dateTwo) &&
    dateOne.getUTCDate() === dateTwo.getUTCDate()
  )
}

export function endOfMonth(date: Date): Date {
  const newDate = new Date(date)
  const month = newDate.getUTCMonth()
  newDate.setUTCFullYear(newDate.getUTCFullYear(), month + 1, 0)
  return newDate
}

export function startOfMonth(date: Date): Date {
  const newDate = new Date(date)
  newDate.setUTCDate(1)
  return newDate
}

export function isEndOfMonth(date: Date): boolean {
  return isSameDate(date, endOfMonth(date))
}

export function isStartOfMonth(date: Date): boolean {
  return isSameDate(date, startOfMonth(date))
}

export function compareDate(dateOne: Date, dateTwo: Date): number {
  switch (true) {
    case dateOne.getUTCFullYear() > dateTwo.getUTCFullYear():
      return 1
    case dateTwo.getUTCFullYear() > dateOne.getUTCFullYear():
      return -1
    case dateOne.getUTCMonth() > dateTwo.getUTCMonth():
      return 1
    case dateTwo.getUTCMonth() > dateOne.getUTCMonth():
      return -1
    case dateOne.getUTCDate() > dateTwo.getUTCDate():
      return 1
    case dateTwo.getUTCDate() > dateOne.getUTCDate():
      return -1
    default:
      return 0
  }
}

export function isBetweenDates(from: Date, to: Date, date: Date): boolean {
  return compareDate(from, date) <= 0 && compareDate(to, date) >= 0
}

export function isSunday(date: Date): boolean {
  return date.getUTCDay() === 0
}

export function isWeekend(date: Date): boolean {
  const day = date.getUTCDay()
  return day === 0 || day === 6
}

export function zeroPad(value: number, length = 2): string {
  let s = value.toString()
  while (s.length < length) {
    s = '0' + s
  }
  return s
}

export function toServerDate(dt: Date): string {
  if (!dt) return ''
  return [
    dt.getUTCFullYear(),
    zeroPad(dt.getUTCMonth() + 1),
    zeroPad(dt.getUTCDate()),
  ].join('')
}

export function properInput(e: string): string {
  let inputValue = e
  if (+inputValue > HOURS_INPUT_MAX) inputValue = `${HOURS_INPUT_MAX}`
  if (+inputValue < HOURS_INPUT_MIN) inputValue = `${HOURS_INPUT_MIN}`
  if (inputValue.match(/\.\d\d/))
    inputValue = inputValue.substring(0, inputValue.length - 1)
  if (inputValue.match(/,/)) inputValue = inputValue.replace(/,/g, '.')
  return inputValue
}

export function sortBy<T>(a: T, b: T, list: (keyof T)[], desc = false): number {
  const name = list.shift()
  if (!name) return 0
  const descending = desc ? -1 : 1
  const reverse = typeof a[name] === 'boolean' ? -1 : 1
  switch (true) {
    case a[name] > b[name]:
      return 1 * descending * reverse
    case b[name] > a[name]:
      return -1 * descending * reverse
    default:
      return sortBy(a, b, list, desc)
  }
}

export function round(value: number): number {
  return Math.round(value * 100) / 100
}

interface WeekdaysConfig {
  lang?: string
  type?: 'long' | 'short' | 'narrow'
  removeDot?: boolean
  length?: number
}

export function getWeekdaysNames({
  lang = 'en',
  type = 'long',
  removeDot = false,
  length = undefined,
}: WeekdaysConfig = {}) {
  return Array.from({ length: 7 }, (_, index) =>
    Intl.DateTimeFormat(lang, { weekday: type })
      .format(new Date(0, 0, index))
      .split('')
      .map((letter, index) => (index === 0 ? letter.toUpperCase() : letter))
      .join('')
      .replace(removeDot ? '.' : '', '')
      .slice(0, length)
  )
}

interface MonthsConfig {
  lang?: string
  type?: 'long' | 'short' | 'narrow' | 'numeric' | '2-digit'
  removeDot?: boolean
  length?: number
}

export function getMonthsDays({
  lang = 'en',
  type = 'long',
  removeDot = false,
  length = undefined,
}: MonthsConfig = {}) {
  return Array.from({ length: 12 }, (_, index) =>
    Intl.DateTimeFormat(lang, { month: type })
      .format(new Date(0, index + 1, 0))
      .split('')
      .map((letter, index) => (index === 0 ? letter.toUpperCase() : letter))
      .join('')
      .replace(removeDot ? '.' : '', '')
      .slice(0, length)
  )
}
