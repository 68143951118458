import React from 'react'
import cx from 'classnames'
import { store } from '../store/Store'
import { observer } from 'mobx-react-lite'
import { i18n } from '../i18n/i18n'
import { CalendarDayStore } from '../store/CalendarStore'
import { isSameDate, isSunday } from '../utils/utils'

const DayBox = observer(
  ({
    day,
    hours,
    edited,
  }: {
    day: CalendarDayStore
    hours: number
    edited: boolean
  }) => {
    const { openDayView, closeDayView, isVacationActive, selectedDay } =
      store.ui
    const { setDayOff } = store.calendar
    const { hoursPerDay } = store.user
    const { date, off } = day

    const onClickHandler = () =>
      selectedDay && isSameDate(date, selectedDay)
        ? closeDayView()
        : openDayView(date)
    const isSelected = selectedDay && isSameDate(date, selectedDay)

    return (
      <div className='day-box'>
        <div
          className={cx('box', {
            off,
            sunday: isSunday(date),
            selected: isSelected,
          })}
          onClick={onClickHandler}
        >
          <div className='box-column'>
            <div className='day'>{date.getUTCDate()}</div>
            <div className='weekday'>
              {i18n.WEEKDAYS_SHORT_ALT[date.getUTCDay()]}
            </div>
          </div>
          <div className='box-column'>
            <div className='hours-per-day'>{!off ? hoursPerDay : '\xa0'}</div>
            <div className={cx('hours', { edited })}>
              {hours > 0 ? hours : '\xa0'}
            </div>
          </div>
        </div>
        <div className={cx('day-settings', { active: isVacationActive })}>
          <input
            type='checkbox'
            checked={!off}
            onChange={() => setDayOff(date)}
            tabIndex={-1}
          />
        </div>
      </div>
    )
  }
)

const Calendar = observer(() => {
  const { bookedDays, editedDays } = store.month
  const { month } = store.calendar

  return (
    <div className='calendar-control'>
      {month.map((day, index) => (
        <DayBox
          key={day.date.getTime()}
          day={day}
          hours={bookedDays[index]}
          edited={editedDays[index]}
        />
      ))}
    </div>
  )
})

export default Calendar
