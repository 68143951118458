import React from 'react'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { store } from '../store/Store'
import { Icons } from './Icons'
import { Button } from '@blueprintjs/core'
import { useRef } from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import { i18n } from '../i18n/i18n'

const FilterInput = observer(
  ({
    title,
    value,
    onChange,
  }: {
    title: string
    value: string
    onChange: (value: string) => void
  }) => {
    const { isAsideOpen } = store.elements.filters

    return (
      <div className='input-group'>
        <span>{title}:</span>
        <div className='input'>
          {isAsideOpen ? (
            <input
              className='form-control'
              value={value || ''}
              onChange={e => onChange(e.target.value)}
            />
          ) : (
            <input
              className='form-control'
              value={''}
              onChange={() => null}
              tabIndex={-1}
            />
          )}
          {value && (
            <Button
              className='close-button'
              minimal
              onClick={() => onChange('')}
            >
              <Icons.Close />
            </Button>
          )}
        </div>
      </div>
    )
  }
)

const Filters = observer(() => {
  const { filters } = store.elements
  const { isAsideOpen, toggleAsideOpen, isFilterActive, toggleFilters } =
    store.elements.filters
  const ref = useRef<HTMLDivElement>(null)
  useResizeObserver(
    ref,
    entry => (entry.target.scrollLeft = entry.target.scrollWidth)
  )

  return (
    <div ref={ref} className={cx('content-left', { active: isAsideOpen })}>
      <div className='filters-title-wrpper'>
        <div className='filters-title'>
          <span>{i18n.FILTERS}</span>
          <button onClick={toggleAsideOpen}>
            <Icons.Close />
          </button>
        </div>
      </div>

      <div className={cx('filters', { active: isAsideOpen })}>
        <div className='filters-buttons-wrapper'>
          <button
            className={cx('btn apply', {
              'btn-secondary': isFilterActive,
              'btn-primary': !isFilterActive,
            })}
            onClick={toggleFilters}
          >
            {isFilterActive ? i18n.DISABLE_FILTERS : i18n.APPLY_FILTERS}
          </button>
        </div>

        <div className='input-checkbox'>
          <div>Only&nbsp;open: </div>
          &nbsp;
          <input
            type='checkbox'
            checked={filters.onlyOpen}
            onChange={filters.toggleOnlyOpen}
          />
        </div>

        <FilterInput
          title='Project definition'
          value={filters.projectNumber}
          onChange={filters.setProjectNumber}
        />
        <FilterInput
          title='Project description'
          value={filters.projectDescription}
          onChange={filters.setProjectDescription}
        />
        <FilterInput
          title='WBS element'
          value={filters.wbsElement}
          onChange={filters.setWbsElement}
        />
        <FilterInput
          title='WBS description'
          value={filters.description}
          onChange={filters.setDescription}
        />
        <FilterInput
          title='Proj. lead CompCode'
          value={filters.leadingCompany}
          onChange={filters.setLeadingCompany}
        />
        <FilterInput
          title='Proj. WBS. Char1'
          value={filters.characteristic1}
          onChange={filters.setCharacteristic1}
        />
        <FilterInput
          title='Proj. WBS. Char2'
          value={filters.characteristic2}
          onChange={filters.setCharacteristic2}
        />
        <FilterInput
          title='Proj. WBS. Char3'
          value={filters.characteristic3}
          onChange={filters.setCharacteristic3}
        />
        <FilterInput
          title='GDPEP Phase'
          value={filters.gdpepPhase}
          onChange={filters.setGdpepPhase}
        />
        <FilterInput
          title='Responsible CC'
          value={filters.costCenter}
          onChange={filters.setCostCenter}
        />
        <FilterInput
          title='Request. cost center'
          value={filters.reqCostCenter}
          onChange={filters.setReqCostCenter}
        />
        <FilterInput
          title='Profit Center'
          value={filters.profitCenter}
          onChange={filters.setProfitCenter}
        />
        <FilterInput
          title='Person Resp. (Name)'
          value={filters.manager}
          onChange={filters.setManager}
        />
        <FilterInput
          title='Applicant (Name)'
          value={filters.reqEmployees}
          onChange={filters.setReqEmployees}
        />
        <FilterInput
          title='PPM ID'
          value={filters.ppm}
          onChange={filters.setPpm}
        />
        <FilterInput
          title='Product Level 4'
          value={filters.prodLvl}
          onChange={filters.setProdLvl}
        />
        <FilterInput
          title='End Customer (Name)'
          value={filters.name1}
          onChange={filters.setName1}
        />
      </div>
    </div>
  )
})

export default Filters
