import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'classnames'
import { store } from '../store/Store'
import { Classes } from '@blueprintjs/core'
import { i18n } from '../i18n/i18n'

export const LoginDialog = observer(() => {
  const { isOpenLoginDialog } = store.login
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    if (isOpenLoginDialog) {
      setTimeout(() => store.login.isOpenLoginDialog && setVisible(true), 2000)
    } else {
      setVisible(false)
    }
  }, [isOpenLoginDialog])

  if (!isOpenLoginDialog) return null

  return (
    <div className={cx('login-dialog', { 'non-visible': !isVisible })}>
      <div className='bp3-portal'>
        <div className='bp3-overlay-backdrop bp3-overlay-enter-done'>
          <div className='bp3-dialog-container'>
            <div className='bp3-dialog'>
              <div className='bp3-dialog-header'>
                <h4 className='bp3-heading'>
                  {i18n.AUTHENTICATION_INFORMATION}
                </h4>
              </div>
              <div className='bp3-dialog-body'>
                <div
                  className={cx(
                    'day-dialog',
                    Classes.DIALOG_BODY,
                    'animate-day-dialog'
                  )}
                >
                  <iframe
                    title='Login'
                    src='/zfz/ca_se_cats/$metadata?xhr-logon=iframe'
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
