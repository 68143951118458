import { Intent } from '@blueprintjs/core'
import { AppToaster } from '../components/Toster'
import { fakeFetch } from './fakeApi'
import { Day, PSElement, Status } from '../store/PSElement'
import { toServerDate } from '../utils/utils'
import { Period } from '../store/UserStore'
import { store } from '../store/Store'
import { getLang } from '../i18n/i18n'

const PATH_PREFIX = '/zfz/ca_se_cats'
const SAP_LANGUAGE = `sap-language=${getLang()}`

type ElementResponse = {
  wbs_element: string
  description: string
  project_number: string
  project_description: string
  status: Status
  end_date: string
}

type HoursResponse = {
  wbs_element: string
  work_date: string
  hours: number
  comment: string
}

export type OpenPeriodResponse = {
  from: string
  to: string
}

type ConfigResponse = {
  cost_center: string
  username: string
  open_periods: OpenPeriodResponse[]
  preferences: string
}

type PeriodResponse = {
  period: string
  value: string
}

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE'

type JsonResponse<T> = {
  errors?: ErrorResponse[]
  data?: T
}

type TextResponse = {
  errors?: ErrorResponse[]
  data?: string
  status?: number
}

type ErrorResponse = {
  error: string
}

class API {
  async fetch<T>(
    method: Method,
    _path: string,
    body?: unknown
  ): Promise<JsonResponse<T>> {
    const options = {
      method,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
        'x-xhr-logon': 'accept="iframe,strict-window,window"',
      },
      body: body ? JSON.stringify(body) : undefined,
    } as RequestInit

    let path = _path
    if (/\?/.test(path)) {
      path += '&'
    } else {
      path += '?'
    }
    path += SAP_LANGUAGE

    const parseText = async (resp: Response): Promise<TextResponse> => {
      if (resp.status === 403) {
        store.login.openLoginDialog()
        while (store.login.isOpenLoginDialog) {
          await new Promise(res => setTimeout(res, 100))
        }
        resp = await fetch(PATH_PREFIX + path, options)
      }
      if (resp.ok || resp.status === 400) {
        return { data: await resp.text(), status: resp.status }
      }

      store.error.setApplicationServerError(await resp.text())

      if (resp.status === 500) {
        return { errors: [{ error: `${resp.status}: Internal Server Error` }] }
      }

      return { errors: [{ error: `${resp.status}: ${resp.statusText}` }] }
    }

    const parseJSON = async (resp: TextResponse): Promise<JsonResponse<T>> => {
      if (resp.data) {
        try {
          const json = JSON.parse(resp.data)
          if (resp.status === 400) return { errors: json as ErrorResponse[] }
          return { data: json }
        } catch (error) {
          if (error instanceof Error)
            return { errors: [{ error: `${error.message}` }] }
          else return { errors: [{ error: 'Parsing JSON problem' }] }
        }
      } else {
        return { errors: resp.errors }
      }
    }

    const parseError = async (
      resp: JsonResponse<T>
    ): Promise<JsonResponse<T>> => {
      if (resp.errors?.length) {
        const errors = [...new Set(resp.errors.map(e => e.error))]
        for (const error of errors) {
          AppToaster.show({ message: error, intent: Intent.DANGER })
        }
      }
      return resp
    }

    if (process.env.REACT_APP_FAKE_API) {
      return parseError(
        (await fakeFetch(path, method, body)) as JsonResponse<T>
      )
    }

    try {
      return await fetch(PATH_PREFIX + path, options)
        .then(parseText)
        .then(parseJSON)
        .then(parseError)
    } catch (error) {
      AppToaster.show({ message: `${error}`, intent: Intent.DANGER })
      return { errors: [{ error: `${error}` }] }
    }
  }

  async getConfig(): Promise<ConfigResponse> {
    const response = await this.fetch<ConfigResponse>('GET', '/config')
    return (
      response.data || {
        cost_center: '',
        username: '',
        open_periods: [],
        preferences: '',
      }
    )
  }

  async updateConfig(preferences: string): Promise<void> {
    await this.fetch<void>('POST', '/config', { preferences })
  }

  async getPeriods(from = '', to = ''): Promise<PeriodResponse[]> {
    const response = await this.fetch<PeriodResponse[]>(
      'GET',
      '/periods?from=' + from + '&to=' + to
    )
    return response.data || []
  }

  async updatePeriods(periods: Period[]): Promise<void> {
    await this.fetch<void>(
      'POST',
      '/periods',
      periods.map(period => ({
        period: period.period,
        value: period.value,
      }))
    )
  }

  async getAllElements(): Promise<PSElement[]> {
    const response = await this.fetch<ElementResponse[]>(
      'GET',
      '/elements_search'
    )
    if (response.data)
      return response.data.map(
        element =>
          new PSElement(
            element.wbs_element,
            element.wbs_element,
            element.project_description,
            element.description,
            element.status,
            element.end_date !== '0000-00-00' ? element.end_date : ''
          )
      )
    return []
  }

  async getElements(elements: string[]): Promise<PSElement[]> {
    if (!elements.length) return []
    const response = await this.fetch<ElementResponse[]>(
      'GET',
      `/elements?wbs_elements=${elements.join(';')}`
    )
    if (response.data)
      return response.data.map(
        element =>
          new PSElement(
            element.wbs_element,
            element.wbs_element,
            element.project_description,
            element.description,
            element.status,
            element.end_date !== '0000-00-00' ? element.end_date : ''
          )
      )
    return []
  }

  async searchElements(search: string): Promise<PSElement[]> {
    if (!search) return []
    const response = await this.fetch<ElementResponse[]>(
      'GET',
      `/elements_search?${search}`
    )
    if (response.data)
      return response.data.map(
        element =>
          new PSElement(
            element.wbs_element,
            element.wbs_element,
            element.project_description,
            element.description,
            element.status,
            element.end_date !== '0000-00-00' ? element.end_date : ''
          )
      )
    return []
  }

  async getDays(from: Date, to: Date): Promise<HoursResponse[]> {
    const response = await this.fetch<HoursResponse[]>(
      'GET',
      `/hours?date_from=${toServerDate(from)}&date_to=${toServerDate(to)}`
    )
    return response.data || []
  }

  async getAllDays(): Promise<HoursResponse[]> {
    const response = await this.fetch<HoursResponse[]>('GET', '/hours')
    return response.data || []
  }

  async updateDays(days: Day[]): Promise<void> {
    if (!days.length) return
    await this.fetch<void>(
      'POST',
      '/hours',
      days.map(d => ({
        work_date: toServerDate(d.date),
        wbs_element: d.elementID,
        hours: d.hours,
        comment: d.comment,
      }))
    )
  }
}

export const api = new API()
