import { makeAutoObservable } from 'mobx'
import { store } from './Store'

export type CalendarDayStore = {
  date: Date
  off: boolean
}

export class CalendarStore {
  constructor() {
    makeAutoObservable(this)
  }

  get month(): CalendarDayStore[] {
    const { startOfMonth } = store.ui
    const { period } = store.user

    const days: CalendarDayStore[] = []
    for (let index = 1; index < 32; index++) {
      const date = new Date(startOfMonth)
      date.setUTCDate(index)

      if (date.getUTCDate() === 1 && index !== 1) break
      days.push({ date, off: !!period.current[index] })
    }
    return days
  }

  isDayOff = (date: Date): boolean => {
    return !!store.user.period.current[date.getUTCDate()]
  }

  setDayOff = (date: Date): void => {
    store.user.period.toggleFreeDay(date.getUTCDate())
  }
}
