import { addZero } from '../utils/utils'

abstract class AbstractUrlParams {
  constructor(
    private urlSearchParams = new URLSearchParams(window.location.search)
  ) {}

  get params(): string {
    return '?' + this.urlSearchParams.toString()
  }

  protected get = (param: string): string | null => {
    return this.urlSearchParams.get(param)
  }

  protected set = (param: string, value: string | null): void => {
    if (value) this.urlSearchParams.set(param, value)
    else this.urlSearchParams.delete(param)
  }
}

export class UrlParams extends AbstractUrlParams {
  get month(): Date | null {
    const month = this.get('month')
    return month ? new Date(`${month.slice(0, 4)}-${month.slice(-2)}-01`) : null
  }

  set month(value: Date | null) {
    this.set(
      'month',
      value && `${value.getUTCFullYear()}${addZero(value.getUTCMonth() + 1)}`
    )
  }

  get element(): string | null {
    return this.get('element')
  }

  set element(value: string | null) {
    this.set('element', value)
  }

  get date(): Date | null {
    const date = this.get('date')
    return date
      ? new Date(`${date.slice(0, 4)}-${date.slice(-4, -2)}-${date.slice(-2)}`)
      : null
  }

  set date(value: Date | null) {
    this.set(
      'date',
      value &&
        `${value.getUTCFullYear()}${addZero(value.getUTCMonth() + 1)}${addZero(
          value.getUTCDate()
        )}`
    )
  }
}
