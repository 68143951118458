import React from 'react'
import { observer } from 'mobx-react-lite'
import { store } from '../store/Store'

const ProgressBar = observer(() => {
  const { percentComplete, percentDifferent, percentSaved } = store.month

  let value1 = 0
  let className1 = ''
  let value2 = 0
  let className2 = ''

  if (percentDifferent >= 0) {
    value1 = percentSaved
    value2 = percentDifferent
    className2 = 'bg-warning'
  } else {
    value1 = percentComplete
    value2 = -percentDifferent
    className2 = 'progress-striped'
  }

  if (value1 === 100) {
    className1 = 'bg-success'
  }
  if (value1 > 100) {
    value1 = 100
    value2 = 0
    className1 = 'bg-danger'
  } else if (value1 + value2 > 100) {
    value2 = 100 - value1
    className1 = 'bg-danger'
  }

  return (
    <div>
      <div className='progress'>
        <div
          className={`progress-bar ${className1}`}
          role='progressbar'
          style={{ width: `${value1}%` }}
        ></div>
        <div
          className={`progress-bar ${className2}`}
          role='progressbar'
          style={{ width: `${value2}%` }}
        ></div>
      </div>
    </div>
  )
})

export default ProgressBar
