import React, { useRef } from 'react'

const HoursTagInput = ({
  value,
  icon,
  onChange,
  onKeyDown,
  onBlur,
  changed = false,
  danger = false,
  success = false,
  muted = false,
}: {
  value: string
  icon: JSX.Element
  changed?: boolean
  danger?: boolean
  success?: boolean
  muted?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)

  let color = 'blue'
  if (muted) color = 'gray'
  if (success) color = 'green'
  if (changed) color = 'yellow'
  if (danger) color = 'red'

  return (
    <div
      className={`hours-tag-input ${color}`}
      onClick={() => {
        ref.current?.focus()
        ref.current?.select()
      }}
    >
      {icon}
      <div>
        <input
          ref={ref}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
        <small>h</small>
      </div>
    </div>
  )
}

export default HoursTagInput
