import {
  addZero,
  endOfMonth,
  isBetweenDates,
  isWeekend,
  sleep,
  startOfMonth,
} from '../utils/utils'

export async function fakeFetch(
  path: string,
  method: string,
  body?: unknown
): Promise<unknown> {
  if (window.location.hostname === 'localhost') await sleep(0)
  else await sleep(Math.floor(Math.random() * 10) * 100 + 200)

  if (method === 'GET')
    switch (true) {
      case !!path.match(/elements_search/):
        return { data: ELEMENTS }
      case !!path.match(/elements/):
        return { data: ELEMENTS }
      case !!path.match(/config/):
        return { data: CONFIG }
      case !!path.match(/hours/):
        return { data: getHours(path) }
      default:
        return {}
    }
  if (method === 'POST')
    switch (true) {
      case !!path.match(/hours/):
        addHours(body as HoursResponse[])
        return {}
      default:
        return {}
    }
  return {}
}

type HoursResponse = {
  wbs_element: string
  work_date: string
  hours: number
  comment: string
}

const addHours = (days: HoursResponse[]) => {
  for (const day of days) {
    HOURS = HOURS.filter(d => {
      return !(
        toServerDate(d.work_date) === day.work_date &&
        d.wbs_element === day.wbs_element
      )
    })
  }
  HOURS.push(
    ...days.map(d => ({ ...d, work_date: fromServerDate(d.work_date) }))
  )
}

const getHours = (path: string): unknown => {
  const [match = null, from = '', to = ''] =
    path.match(/date_from=(\d*)&date_to=(\d*)/) || []
  if (!match)
    return HOURS.map(d => ({ ...d, work_date: toWorkDate(d.work_date) }))
  return HOURS.filter(day =>
    isBetweenDates(fromServerDate(from), fromServerDate(to), day.work_date)
  ).map(d => ({ ...d, work_date: toWorkDate(d.work_date) }))
}

const fromServerDate = (date: string): Date => {
  if (!date) return new Date()
  const [match = null, year = '', month = '', day = ''] =
    date.match(/(\d{4})(\d{2})(\d{2})/) || []
  if (!match) new Date()
  return new Date(`${year}-${month}-${day}`)
}

const toServerDate = (date: Date): string => {
  const year = date.getUTCFullYear()
  const month = date.getUTCMonth() + 1
  const day = date.getUTCDate()
  return `${year}${addZero(month)}${addZero(day)}`
}

const toWorkDate = (date: Date): string => {
  const year = date.getUTCFullYear()
  const month = date.getUTCMonth() + 1
  const day = date.getUTCDate()
  return `${year}-${addZero(month)}-${addZero(day)}`
}

const getDaysOfMonth = (monthShift = 0): Date[] => {
  const days: Date[] = []
  for (let index = 1; index < 32; index++) {
    const date = new Date()
    date.setUTCDate(1)
    date.setUTCMonth(date.getUTCMonth() + monthShift)
    date.setUTCDate(index)

    if (date.getUTCDate() === 1 && index !== 1) break
    if (!isWeekend(date)) days.push(date)
  }
  return days
}

const CONFIG = {
  cost_center: 'CP2077',
  username: 'NIGHTR22',
  open_periods: [
    // {
    //   from: '2021-05-30',
    //   to: '2021-05-30',
    // },
    // {
    //   from: '2021-05-01',
    //   to: '2021-07-01',
    // },
    {
      from: startOfMonth(new Date()),
      to: endOfMonth(new Date()),
    },
  ],
  preferences: JSON.stringify({
    hoursPerDay: 7.5,
    percentTimeToBook: 100,
    pinned: ['CP-000001.01.04'],
    hidden: [],
  }),
}

const TODAY = new Date()
const CHANGED_DAY = new Date()

let HOURS = [
  {
    work_date: TODAY,
    wbs_element: 'CP-000001.01.05',
    comment: 'Development',
    hours: 2,
  },
  {
    work_date: new Date(CHANGED_DAY.setDate(TODAY.getDate() - 1)),
    wbs_element: 'CP-000001.01.06',
    comment: 'Development',
    hours: 4,
  },
  {
    work_date: new Date(CHANGED_DAY.setDate(TODAY.getDate() - 2)),
    wbs_element: 'CP-000001.01.07',
    comment: 'Development',
    hours: 1,
  },
  {
    work_date: new Date(CHANGED_DAY.setDate(TODAY.getDate() - 3)),
    wbs_element: 'CP-000001.03.04',
    comment: 'Preparing documentation',
    hours: 4,
  },
  {
    work_date: new Date(CHANGED_DAY.setDate(TODAY.getDate() - 4)),
    wbs_element: 'CP-000001.09.13',
    comment: 'Development',
    hours: 5,
  },
  {
    work_date: new Date(CHANGED_DAY.setDate(TODAY.getDate() - 3)),
    wbs_element: 'CP-000001.09.16',
    comment: '',
    hours: 5,
  },
  {
    work_date: new Date(CHANGED_DAY.setDate(TODAY.getDate() - 3)),
    wbs_element: 'IO-000012.01.03',
    comment: '',
    hours: 2,
  },
]

const ELEMENTS = [
  {
    wbs_element: 'CP-000001.01.04',
    description: 'Task development 2021/1678',
    project_number: 'CP-000001.01',
    project_description: 'Project for Manufacturing',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.01.05',
    description: 'Task development 2021/1679',
    project_number: 'CP-000001.01',
    project_description: 'Project for Manufacturing',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.01.06',
    description: 'Task development 2021/1680',
    project_number: 'CP-000001.01',
    project_description: 'Project for Manufacturing',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.01.07',
    description: 'Task development 2021/1681',
    project_number: 'CP-000001.01',
    project_description: 'Project for Manufacturing',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.01.08',
    description: 'Task development 2021/1682',
    project_number: 'CP-000001.01',
    project_description: 'Project for Manufacturing',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.01.09',
    description: 'Task development 2021/1683',
    project_number: 'CP-000001.02',
    project_description: 'Project for Manufacturing',
    status: 'blocked',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.01.10',
    description: 'Task development 2021/1684',
    project_number: 'CP-000001.03',
    project_description: 'Project for Manufacturing',
    status: 'blocked',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.01.11',
    description: 'Task development 2021/1685',
    project_number: 'CP-000001.04',
    project_description: 'Project for Manufacturing',
    status: 'blocked',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.03.01',
    description: 'Task for UAT 123',
    project_number: 'CP-000001.03',
    project_description: 'Project for new plant',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.03.02',
    description: 'Analysis 123',
    project_number: 'CP-000001.03',
    project_description: 'Project for new plant',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.03.03',
    description: 'Data migration',
    project_number: 'CP-000001.03',
    project_description: 'Project for new plant',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.03.04',
    description: 'Preparing documentation',
    project_number: 'CP-000001.03',
    project_description: 'Project for new plant',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.09.13',
    description: 'Task development 2022/0001',
    project_number: 'CP-000001.09',
    project_description: 'Rollout project 123',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.09.14',
    description: 'Task development 2022/0002',
    project_number: 'CP-000001.09',
    project_description: 'Rollout project 123',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.09.15',
    description: 'Task development 2022/0003',
    project_number: 'CP-000001.09',
    project_description: 'Rollout project 123',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.09.16',
    description: 'Task development 2022/0004',
    project_number: 'CP-000001.09',
    project_description: 'Rollout project 123',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'CP-000001.09.17',
    description: 'Task development 2022/0005',
    project_number: 'CP-000001.09',
    project_description: 'Rollout project 123',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'IO-000012.01.01',
    description: 'Development work 2022/01/0001',
    project_number: 'IO-000012.01',
    project_description: 'Rollout project 123',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'IO-000012.01.02',
    description: 'Development work 2022/01/0002',
    project_number: 'IO-000012.01',
    project_description: 'Rollout project 123',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'IO-000012.01.03',
    description: 'Development work 2022/01/0003',
    project_number: 'IO-000012.01',
    project_description: 'Rollout project 123',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'IO-000012.01.04',
    description: 'Development work 2022/01/0004',
    project_number: 'IO-000012.01',
    project_description: 'Rollout project 123',
    status: 'blocked',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'IO-000012.01.05',
    description: 'Development work 2022/01/0005',
    project_number: 'IO-000012.01',
    project_description: 'Rollout project 123',
    status: 'blocked',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'IO-000012.01.06',
    description: 'Development work 2022/01/0006',
    project_number: 'IO-000012.01',
    project_description: 'Rollout project 123',
    status: 'blocked',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'IO-000012.05.01',
    description: 'Development work 2022/05/0001',
    project_number: 'IO-000012.05',
    project_description: 'New solution 56',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'IO-000012.05.02',
    description: 'Development work 2022/05/0002',
    project_number: 'IO-000012.05',
    project_description: 'New solution 56',
    status: 'open',
    end_date: '0000-00-00',
  },
  {
    wbs_element: 'IO-000012.05.03',
    description: 'Development work 2022/05/0003',
    project_number: 'IO-000012.05',
    project_description: 'New solution 56',
    status: 'open',
    end_date: '0000-00-00',
  },
]
