import { store } from '../store/Store'
import { addZero } from './utils'
import * as XLSX from 'xlsx'

export const exportToExcel = (): void => {
  const { year, month } = store.ui
  const { filtered } = store.elements
  const { username } = store.user

  const worksheet = XLSX.utils.json_to_sheet(
    filtered.map(element => ({
      Year: year,
      Month: month + 1,
      User: username,
      'WBS ID': element.id,
      'WBS Description': element.description,
      'Project Description': element.title,
      'All hours': element.allHours,
      'Booked Hours': element.hours,
    }))
  )

  worksheet['!cols'] = [
    { wch: 5 },
    { wch: 5 },
    { wch: 9 },
    { wch: 20 },
    { wch: 40 },
    { wch: 40 },
    { wch: 10 },
    { wch: 10 },
  ]

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'CATS')

  const title = `${year}-${addZero(month + 1)}-${username}-CATS.xlsx`
  XLSX.writeFile(workbook, title)
}
