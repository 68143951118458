import { makeAutoObservable } from 'mobx'

export class LoginStore {
  isOpenLoginDialog = false

  constructor() {
    makeAutoObservable(this)
    const onMessageHandler = (event: MessageEvent) => {
      if (event && typeof event.data === 'string') {
        try {
          const data = JSON.parse(event.data)
          if (data.xhrLogon && data.xhrLogon.status === 200) {
            this.closeLoginDialog()
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
        }
      }
    }
    window.addEventListener('message', onMessageHandler, false)
  }

  openLoginDialog = (): void => {
    this.isOpenLoginDialog = true
  }

  closeLoginDialog = (): void => {
    this.isOpenLoginDialog = false
  }
}
