import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { i18n } from '../i18n/i18n'
import { store } from '../store/Store'
import { Icons } from './Icons'
import { Popover2 } from '@blueprintjs/popover2'
import { Button } from '@blueprintjs/core'
import HoursTag from './HoursTag'
import exportToPrinter from '../utils/exportToPrinter'
import { exportToExcel } from '../utils/exportToExcel'
import cx from 'classnames'
import { ToolTipButton } from './ToolTipButton'
import { ToolTip } from './ToolTip'
import TextTag from './TextTag'
import { ReactComponent as Logo } from '../assets/img/si4.svg'

const useInputNumber = (
  initValue: number,
  setter: (v: string) => number
): [string, (event: React.ChangeEvent<HTMLInputElement>) => void] => {
  const [value, setValue] = useState(`${initValue}`)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value.replace(/,/g, '.')
    const result = setter(newValue)
    setValue(+newValue === result ? newValue : `${result}`)
  }

  return [value, onChange]
}

const Trademark: React.FC = () => {
  return (
    <div className='trademark'>
      <Logo />
      <div className='app-name'>{i18n.APP_TITLE}</div>
    </div>
  )
}

const Hours = observer(() => {
  const { allTime, allLeftTime, allBookedTime, allDifferentTime } = store.month

  return (
    <div className='hours'>
      <ToolTip
        value={
          <HoursTag
            value={allBookedTime}
            icon={<Icons.CalendarMonth />}
            changed={allDifferentTime !== 0}
            success={allLeftTime === 0}
          />
        }
        tooltip={i18n.BOOKED}
      />

      <ToolTip
        value={
          <HoursTag
            value={allLeftTime}
            icon={<Icons.ClockOutline />}
            muted
            danger={allLeftTime < 0}
          />
        }
        tooltip={i18n.LEFT}
      />

      <ToolTip
        value={
          <HoursTag value={allTime} icon={<Icons.CalendarClock />} muted />
        }
        tooltip={i18n.PLANNED}
      />
    </div>
  )
})

const MonthSelector = observer(() => {
  const {
    year,
    month,
    todayMonth,
    isTodayMonth,
    backToNow,
    nextMonth,
    previousMonth,
  } = store.ui

  return (
    <div>
      <div className='month-selector'>
        <button className='btn' onClick={() => previousMonth()} tabIndex={-1}>
          <Icons.ChevronLeft />
        </button>
        <div>
          <div>
            {i18n.MONTHS[month]} {year}
          </div>
          {!isTodayMonth && (
            <div className='month-selector-back'>
              <a className='link' onClick={() => backToNow()} tabIndex={-1}>
                ➝ {i18n.BACK_TO} {i18n.MONTHS[todayMonth]}
              </a>
            </div>
          )}
        </div>
        <button className='btn' onClick={() => nextMonth()} tabIndex={-1}>
          <Icons.ChevronRight />
        </button>
      </div>
    </div>
  )
})

const SettingsPopover = observer(() => {
  const {
    hoursPerDay,
    percentTimeToBook,
    setHoursPerDay,
    setPercentTimeToBook,
  } = store.user
  const [valueHoursPerDay, onChangeHoursPerDay] = useInputNumber(
    hoursPerDay,
    setHoursPerDay
  )
  const [valuePercentTimeToBook, onChangePercentTimeToBook] = useInputNumber(
    percentTimeToBook,
    setPercentTimeToBook
  )

  return (
    <div className='settings-popover'>
      <h5>{i18n.SETTINGS}</h5>
      <div>
        <span>{i18n.HOURS_PER_DAY}</span>
        <input
          className='form-control'
          value={valueHoursPerDay}
          onChange={onChangeHoursPerDay}
        />
        <span>h</span>
        <span>{i18n.TIME_TO_BOOK}</span>
        <input
          className='form-control'
          value={valuePercentTimeToBook}
          onChange={onChangePercentTimeToBook}
        />
        <span>%</span>
      </div>
    </div>
  )
})

const ExportPopover = observer(() => {
  return (
    <div className='print-popover'>
      <h5>{i18n.EXPORT_OPTIONS}</h5>
      <div>
        <ToolTipButton
          icon={<Icons.Printer />}
          onClick={exportToPrinter}
          tooltip={i18n.PRINT}
        />
        <ToolTipButton
          icon={<Icons.ExcelExport />}
          onClick={exportToExcel}
          tooltip={i18n.EXPORT_TO_EXCEL}
        />
      </div>
    </div>
  )
})

const Settings = observer(() => {
  const { toggleVacation } = store.ui
  const { isLoading, isSthToSave, save, user } = store
  const { period } = user

  return (
    <div style={{ display: 'flex' }}>
      <ToolTipButton
        icon={<Icons.ContentSaveOutline />}
        onClick={save}
        tooltip={i18n.SAVE}
        disabled={!isSthToSave}
        loading={isLoading}
      />
      <ToolTipButton
        icon={
          <Icons.MapClockOutline
            className={cx({ edited: period.isSthToSave })}
          />
        }
        onClick={toggleVacation}
        tooltip={i18n.WORKS_DAY}
      />
      <Popover2 content={<ExportPopover />} placement='bottom-end'>
        <ToolTipButton
          icon={<Icons.FileDocumentBoxOutline />}
          tooltip={i18n.PRINT_EXPORT}
        />
      </Popover2>
      <Popover2 content={<SettingsPopover />} placement='bottom-end'>
        <ToolTipButton
          icon={<Icons.Settings className={cx({ edited: user.isSthToSave })} />}
          placement='bottom-end'
          tooltip={i18n.SETTINGS}
        />
      </Popover2>
    </div>
  )
})

export const Search = observer(() => {
  const { searchValue, setSearch, isAsideOpen } = store.elements.filters

  return (
    <div className='search'>
      <Icons.Magnify />
      <input
        className='form-control'
        placeholder={i18n.SEARCH_PLACEHOLDER}
        value={searchValue}
        disabled={isAsideOpen}
        onChange={e => setSearch(e.target.value)}
      />
      {searchValue && (
        <Button
          className='close-button'
          minimal
          onClick={() => setSearch('')}
          disabled={isAsideOpen}
        >
          <Icons.Close />
        </Button>
      )}
    </div>
  )
})

const FiltersButton = observer(() => {
  const { toggleAsideOpen, isAsideOpen, isFilterActive, toggleFilters } =
    store.elements.filters

  const onButtonClick = () => {
    if (isFilterActive) {
      toggleFilters()
    }
    toggleAsideOpen()
  }

  return (
    <Button className='btn' minimal onClick={onButtonClick}>
      {isAsideOpen ? <Icons.Filter /> : <Icons.FilterOutline />}
    </Button>
  )
})

const UserData = observer(() => {
  const { constCenter, username } = store.user

  return (
    <div className='hours'>
      <ToolTip
        value={<TextTag value={username} icon={<Icons.AccountOutline />} />}
        tooltip={i18n.USER}
      />
      <ToolTip
        value={
          <TextTag value={constCenter} icon={<Icons.AccountCashOutline />} />
        }
        tooltip={i18n.COST_CENTER}
      />
    </div>
  )
})

const Spacer = () => <div className='spacer' />

const TopBar = observer(() => (
  <header className='topbar'>
    <Trademark />
    <Search />
    <FiltersButton />
    <Spacer />
    <Hours />
    <Spacer />
    <MonthSelector />
    <Spacer />
    <UserData />
    <Settings />
  </header>
))

export default TopBar
