import { observer } from 'mobx-react-lite'
import React from 'react'
import cx from 'classnames'
import { Placement, Tooltip2 } from '@blueprintjs/popover2'

const TOOLTIP_OPEN_DELAY = 300

export const ToolTipButton = observer(
  ({
    icon,
    onClick,
    disabled,
    loading,
    tooltip,
    placement = 'bottom',
  }: {
    icon: React.ReactElement
    onClick?: () => void
    tooltip: string
    placement?: Placement
    disabled?: boolean
    loading?: boolean
  }) => {
    return (
      <Tooltip2
        content={tooltip}
        placement={placement}
        hoverOpenDelay={TOOLTIP_OPEN_DELAY}
      >
        <button
          className={cx('btn tooltip-btn', {
            disabled: disabled || loading,
            loading: loading,
          })}
          onClick={() => !disabled && !loading && onClick && onClick()}
        >
          {loading ? (
            <div className='bp3-spinner bp3-button-spinner'>
              <div className='bp3-spinner-animation'>
                <svg
                  width='20'
                  height='20'
                  strokeWidth='16.00'
                  viewBox='-3.00 -3.00 106.00 106.00'
                >
                  <path
                    className='bp3-spinner-track'
                    d='M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90'
                  ></path>
                  <path
                    className='bp3-spinner-head'
                    d='M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90'
                    pathLength='280'
                    strokeDasharray='280 280'
                    strokeDashoffset='210'
                  ></path>
                </svg>
              </div>
            </div>
          ) : (
            icon
          )}
        </button>
      </Tooltip2>
    )
  }
)
