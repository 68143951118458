import { makeAutoObservable, runInAction } from 'mobx'
import { api, OpenPeriodResponse } from '../api/api'
import { isBetweenDates, compareDate, isWeekend } from '../utils/utils'
import { store } from './Store'

export const HOURS_PER_DAY_MIN = 0
export const HOURS_PER_DAY_MAX = 24

export const PERCENT_TIME_TO_BOOK_MIN = 0
export const PERCENT_TIME_TO_BOOK_MAX = 100

const DEFAULT_HOURS_PER_DAY = 8
const DEFAULT_PERCENT_TIME_TO_BOOK = 100

type OpenPeriod = {
  from: Date
  to: Date
}

type FreeDay = {
  [key: number]: boolean
}

export class Period {
  private _value: string

  constructor(public period: string, public value: string) {
    makeAutoObservable(this)
    this.period = period
    this.value = value
    this._value = value
  }

  get isSthToSave(): boolean {
    return this._value !== this.value
  }

  resetBase(): void {
    this._value = this.value
  }
}

class PeriodStore {
  private periods: Period[] = []
  constructor() {
    makeAutoObservable(this)
    this.init()
  }

  private init = async (): Promise<void> => {
    const periods = await api.getPeriods()
    runInAction(
      () =>
        (this.periods = periods.map(
          period => new Period(period.period, period.value)
        ))
    )
  }

  get current(): FreeDay {
    const result = this.periods.find(
      period => period.period === store.ui.monthYear
    )
    if (result) {
      try {
        return JSON.parse(result.value)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Problem with parsing periods')
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
    return this.getFreeDays
  }

  get getFreeDays(): FreeDay {
    const freeDays: FreeDay = {}
    for (let index = 1; index < 32; index++) {
      const date = new Date(store.ui.startOfMonth)
      date.setUTCDate(index)

      if (date.getUTCDate() === 1 && index !== 1) break
      if (isWeekend(date)) freeDays[index] = true
    }
    return freeDays
  }

  get isSthToSave(): boolean {
    return this.periods.some(period => period.isSthToSave)
  }

  get periodsToSave(): Period[] {
    return this.periods.filter(period => period.isSthToSave)
  }

  toggleFreeDay = (day: number): void => {
    const result = this.periods.find(
      period => period.period === store.ui.monthYear
    )
    if (result) {
      const freeDays = this.current
      if (!freeDays[day]) freeDays[day] = true
      else delete freeDays[day]
      result.value = JSON.stringify(freeDays)
    } else {
      const period = new Period(
        store.ui.monthYear,
        JSON.stringify(this.getFreeDays)
      )
      const freeDays = JSON.parse(period.value)
      if (!freeDays[day]) freeDays[day] = true
      else delete freeDays[day]
      period.value = JSON.stringify(freeDays)
      this.periods.push(period)
    }
  }

  save = async (): Promise<void> => {
    if (!this.isSthToSave) return

    await api.updatePeriods(this.periodsToSave)
    this.periodsToSave.forEach(period => period.resetBase())
  }
}

export class UserStore {
  hoursPerDay = DEFAULT_HOURS_PER_DAY
  percentTimeToBook = DEFAULT_PERCENT_TIME_TO_BOOK
  private _hoursPerDay = DEFAULT_HOURS_PER_DAY
  private _percentTimeToBook = DEFAULT_PERCENT_TIME_TO_BOOK
  username = ''
  constCenter = ''
  openPeriods: OpenPeriod[] = []
  isLoading = false
  period = new PeriodStore()
  pinned: string[] = []
  hidden: string[] = []
  private _pinned: string[] = []
  private _hidden: string[] = []

  constructor() {
    makeAutoObservable(this)
    this.init()
  }

  private init = async (): Promise<void> => {
    const config = await api.getConfig()
    this.setConfig(config.username, config.cost_center)
    this.setOpenPeriods(config.open_periods)

    try {
      const preferences = JSON.parse(config.preferences)
      runInAction(() => {
        if (preferences.hoursPerDay)
          this.hoursPerDay = this._hoursPerDay = preferences.hoursPerDay
        if (preferences.percentTimeToBook)
          this.percentTimeToBook = this._percentTimeToBook =
            preferences.percentTimeToBook
        if (preferences.pinned) this.pinned = this._pinned = preferences.pinned
        if (preferences.hidden) this.hidden = this._hidden = preferences.hidden
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Problem with parsing config')
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  get preferences(): string {
    return JSON.stringify({
      hoursPerDay: this.hoursPerDay,
      percentTimeToBook: this.percentTimeToBook,
      pinned: this.pinned,
      hidden: this.hidden,
    })
  }

  get openPeriodsStart(): Date {
    return this.openPeriods.reduce(
      (p, c) => (compareDate(p, c.from) <= 0 ? p : c.from),
      new Date()
    )
  }

  get openPeriodsEnd(): Date {
    return this.openPeriods.reduce(
      (p, c) => (compareDate(p, c.to) >= 0 ? p : c.to),
      new Date()
    )
  }

  get isEdited(): boolean {
    return (
      this.hoursPerDay !== this._hoursPerDay ||
      this.percentTimeToBook !== this._percentTimeToBook ||
      this.pinned.slice().sort().join() !==
        this._pinned.slice().sort().join() ||
      this.hidden.slice().sort().join() !== this._hidden.slice().sort().join()
    )
  }

  get isSthToSave(): boolean {
    return this.isEdited
  }

  canEditInDate = (date: Date): boolean => {
    return this.openPeriods.some(p => isBetweenDates(p.from, p.to, date))
  }

  setConfig = (username: string, constCenter: string): void => {
    this.username = username
    this.constCenter = constCenter
  }

  setOpenPeriods = (openPeriods: OpenPeriodResponse[]): void => {
    this.openPeriods = openPeriods.map(p => ({
      from: new Date(p.from),
      to: new Date(p.to),
    }))
  }

  setHoursPerDay = (value: string): number => {
    const number = +value
    if (isNaN(number)) return this.hoursPerDay
    if (number < HOURS_PER_DAY_MIN)
      return (this.hoursPerDay = HOURS_PER_DAY_MIN)
    if (number > HOURS_PER_DAY_MAX)
      return (this.hoursPerDay = HOURS_PER_DAY_MAX)
    return (this.hoursPerDay = number)
  }

  setPercentTimeToBook = (value: string): number => {
    const number = +value
    if (isNaN(number)) return this.percentTimeToBook
    if (number < PERCENT_TIME_TO_BOOK_MIN)
      return (this.percentTimeToBook = PERCENT_TIME_TO_BOOK_MIN)
    if (number > PERCENT_TIME_TO_BOOK_MAX)
      return (this.percentTimeToBook = PERCENT_TIME_TO_BOOK_MAX)
    return (this.percentTimeToBook = number)
  }

  savePreferences = async (): Promise<void> => {
    runInAction(() => (this.isLoading = true))

    await this.period.save()

    if (this.isSthToSave) {
      await api.updateConfig(this.preferences)
      runInAction(() => {
        this._hoursPerDay = this.hoursPerDay
        this._percentTimeToBook = this.percentTimeToBook
        this._pinned = this.pinned.map(p => p)
        this._hidden = this.hidden.map(h => h)
      })
    }

    runInAction(() => (this.isLoading = false))
  }
}
