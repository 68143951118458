import { makeAutoObservable } from 'mobx'
import { compareDate, isSameDate, isSameMonth } from '../utils/utils'
import { store } from './Store'

export class Day {
  private baseHours: number
  private baseComment: string

  constructor(
    public readonly date: Date,
    private _hours: number,
    private _comment: string,
    public readonly elementID: string,
    isNew = true
  ) {
    makeAutoObservable(this)

    if (isNew) {
      this.baseHours = 0
      this.baseComment = ''
    } else {
      this.baseHours = _hours
      this.baseComment = _comment
    }
  }

  get isCommentDeleted(): boolean {
    return this._comment !== '' && this._hours === 0
  }

  get isCommentEdited(): boolean {
    return this._comment !== this.baseComment
  }

  get isHoursEdited(): boolean {
    return this._hours !== this.baseHours
  }

  get isEdited(): boolean {
    return this.isHoursEdited || this.isCommentEdited
  }

  get isInitial(): boolean {
    return this.baseHours === 0 && this.baseComment === '' && !this.isEdited
  }

  get hours(): number {
    return this._hours
  }

  set hours(value: number) {
    if (!this.canEdit) return
    this._hours = value < 0 ? 0 : value
  }

  get comment(): string {
    return this._comment
  }

  set comment(value: string) {
    if (!this.canEdit) return
    this._comment = value
  }

  get different(): number {
    return this._hours - this.baseHours
  }

  get element(): PSElement | undefined {
    return store.elements.all.find(element => element.id === this.elementID)
  }

  get canEdit(): boolean {
    if (!store.user.canEditInDate(this.date)) return false
    return this.element?.canEdit || false
  }

  refresh = (day: Day): void => {
    this.baseHours = day._hours
    this.baseComment = day._comment
  }

  reset = (): void => {
    this.baseHours = 0
    this.baseComment = ''
    if (this._hours === 0) {
      this.comment = ''
    }
  }
}

export type Status = 'open' | 'blocked'

export class PSElement {
  constructor(
    public id: string,
    public code: string,
    public title: string,
    public description: string,
    public status: Status,
    public endDate: string
  ) {
    makeAutoObservable(this)
  }

  get days(): Day[] {
    return store.elements.days.filter(day => day.elementID === this.id)
  }

  get hours(): number {
    return this.days
      .filter(({ date }) => store.ui.isSameMonth(date))
      .reduce((p, c) => p + c.hours, 0)
  }

  get different(): number {
    return this.days
      .filter(({ date }) => store.ui.isSameMonth(date))
      .reduce((p, c) => p + c.different, 0)
  }

  get allHours(): number {
    return this.days.reduce((p, c) => p + c.hours, 0)
  }

  get previousHours(): number {
    return this.days
      .filter(
        ({ date }) =>
          !store.ui.isSameMonth(date) && store.ui.compareDate(date) === -1
      )
      .reduce((p, c) => p + c.hours, 0)
  }

  get nextHours(): number {
    return this.days
      .filter(
        ({ date }) =>
          !store.ui.isSameMonth(date) && store.ui.compareDate(date) === 1
      )
      .reduce((p, c) => p + c.hours, 0)
  }

  get selectedDays(): Day[] {
    return this.days.filter(
      ({ date }) =>
        store.ui.selectedDay && isSameDate(date, store.ui.selectedDay)
    )
  }

  get selectedDay(): Day | undefined {
    return this.days.find(({ date }) =>
      isSameDate(date, store.ui.selectedDay || store.ui.endOfMonth)
    )
  }

  get text(): string {
    return `${this.code} - ${this.title} - ${this.description}`
  }

  get hidden(): boolean {
    return store.user.hidden.some(id => id === this.id)
  }

  get pinned(): boolean {
    return store.user.pinned.some(id => id === this.id)
  }

  get isUsedLastTime(): boolean {
    return this.days.some(({ date }) =>
      isSameMonth(date, store.ui.dateOneMonthBefore)
    )
  }

  get isUsedInSelectedMonth(): boolean {
    return this.days.some(({ date }) => store.ui.isSameMonth(date))
  }

  get isVisible(): boolean {
    if (this.pinned) return true
    if (store.ui.isBeforeMonth) return this.isUsedInSelectedMonth
    if (this.isUsedLastTime && !this.hidden) return true
    if (this.hours) return true
    return false
  }

  get isHideIconShow(): boolean {
    return (this.isUsedLastTime && this.hours === 0) || this.hidden
  }

  get isEdited(): boolean {
    return this.days.some(d => d.isEdited)
  }

  get isEditedDay(): boolean {
    return this.selectedDay?.isEdited || false
  }

  get isEditedMonth(): boolean {
    return this.days
      .filter(({ date }) => store.ui.isSameMonth(date))
      .some(d => d.isEdited)
  }

  get canEdit(): boolean {
    return this.status === 'open'
  }

  get comment(): string {
    const { endOfMonth, selectedDay } = store.ui
    const day = this.days.find(day =>
      isSameDate(selectedDay || endOfMonth, day.date)
    )

    return day ? day.comment : ''
  }

  get dayHours(): number {
    const { endOfMonth, selectedDay } = store.ui
    const day = this.days.find(day =>
      isSameDate(selectedDay || endOfMonth, day.date)
    )

    return day ? day.hours : 0
  }

  get day(): Day | undefined {
    const { endOfMonth, selectedDay } = store.ui
    const day = this.days.find(day =>
      isSameDate(selectedDay || endOfMonth, day.date)
    )

    return day
  }

  addHours = (hours: number): void => {
    if (!this.canEdit) return
    const { endOfMonth } = store.ui

    if (hours >= 0) {
      const day = this.days.find(day => isSameDate(endOfMonth, day.date))
      if (day) {
        if (day.hours + hours >= 0 || day.comment)
          day.hours = day.hours + hours >= 0 ? day.hours + hours : 0
        else this.deleteDay(day)
        return
      }

      if (hours <= 0) {
        return
      }

      store.elements.days.push(new Day(endOfMonth, hours, '', this.id))
      return
    }

    const days = this.days
      .sort((a, b) => compareDate(b.date, a.date))
      .filter(d => store.ui.isSameMonth(d.date))
    let hoursToDelete = hours

    for (const day of days) {
      if (day.hours + hoursToDelete < 0) {
        hoursToDelete += day.hours
        day.hours = 0
      } else {
        day.hours += hoursToDelete
        return
      }
    }
  }

  setHours = (hours: number): void => {
    if (!this.canEdit) return
    const { endOfMonth, selectedDay } = store.ui
    const day = this.days.find(day =>
      isSameDate(selectedDay || endOfMonth, day.date)
    )

    if (day) {
      day.hours = hours
      return
    }

    if (hours <= 0) {
      return
    }

    store.elements.days.push(
      new Day(selectedDay || endOfMonth, hours, '', this.id)
    )
  }

  addComment = (comment: string): void => {
    if (!this.canEdit) return
    const { endOfMonth, selectedDay } = store.ui
    const day = this.days.find(day =>
      isSameDate(selectedDay || endOfMonth, day.date)
    )

    if (day) {
      day.comment = comment
      return
    }

    if (!comment) {
      return
    }

    store.elements.days.push(
      new Day(selectedDay || endOfMonth, 0, comment, this.id)
    )
  }

  togglePinned = (): void => {
    if (this.pinned) {
      store.user.pinned = store.user.pinned.filter(id => id !== this.id)
    } else {
      store.user.pinned.push(this.id)
    }
  }

  deleteDay = (day: Day): void => {
    if (!this.canEdit) return
    day.hours = 0
    day.comment = ''
  }

  addDay = (day: Day): void => {
    if (!this.canEdit) return
    store.elements.days.push(day)
  }

  toggleHidden = (): void => {
    if (this.hidden) {
      store.user.hidden = store.user.hidden.filter(id => id !== this.id)
    } else {
      store.user.hidden.push(this.id)
    }
  }
}
