import React from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'classnames'
import { store } from '../store/Store'
import { Classes } from '@blueprintjs/core'

export const ApplicationServerErrorDialog = observer(() => {
  const { applicationServerErrorURI } = store.error

  if (!applicationServerErrorURI) return null

  return (
    <div className='login-dialog'>
      <div className='bp3-portal'>
        <div className='bp3-overlay-backdrop bp3-overlay-enter-done'>
          <div className='bp3-dialog-container'>
            <div className='bp3-dialog'>
              <div className='bp3-dialog-header'>
                <h4 className='bp3-heading'>Application Server Error</h4>
                <button
                  aria-label='Close'
                  className='bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross'
                  onClick={store.error.clearApplicationServerError}
                ></button>
              </div>
              <div className='bp3-dialog-body'>
                <div
                  className={cx(
                    'day-dialog',
                    Classes.DIALOG_BODY,
                    'animate-day-dialog'
                  )}
                >
                  <iframe
                    src={applicationServerErrorURI}
                    style={{ minHeight: '300px' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
