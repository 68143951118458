import React from 'react'
import Calendar from './components/Calendar'
import DayDialog from './components/DayDialog'
import ElementDialog from './components/ElementDialog'
import Tiles from './components/Tiles'
import TopBar from './components/TopBar'
import ProgressBar from './components/ProgressBar'
import SplashScreen from './components/SplashScreen'
import Filters from './components/Filters'
import { LoginDialog } from './components/LoginDialog'
import { ApplicationServerErrorDialog } from './components/ApplicationServerErrorDialog'

const Content = (): JSX.Element => (
  <div className='content'>
    <Filters />
    <Tiles />
  </div>
)

const App = (): JSX.Element => (
  <div className='app'>
    <div className='background-cat' />
    <TopBar />
    <ProgressBar />
    <Calendar />
    <Content />
    <ElementDialog />
    <DayDialog />
    <SplashScreen />
    <ApplicationServerErrorDialog />
    <LoginDialog />
  </div>
)

export default App
